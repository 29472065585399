
:root {
  --var-pad: 2em;
}

//New Home Page
#live-area {

  .border-horz-teal {
    @media (min-width: @screen-md-min) {
      border-left: 1px solid @brand-tertiary;
      border-right: 1px solid @brand-tertiary;
    }
  }

  //.border-bottom-teal {
  //  @media (min-width: @screen-md-min) {
  //    border-bottom: 1px solid #00556e;
  //  }
  //}


  // Custom Heading Handling - New Home page

  h1, .is-style-heading-h1 {
    font-weight: 900;
  }

  .forward-slash-teal strong {
    color: @brand-tertiary;
  }

  // To remove the top margin of the separator when directly proceeded by a heading

  h1, h2, h3, h4, h5 {
    + hr {
      margin-top: 0;
    }
  }


  //Section modifiers

  .is-bg-black li:before {
    color: @brand-secondary;
  }

  .is-bg-gray-dark li:before {
    color: @brand-primary;
  }

  .is-bg-gray-light li:before {
    color: @brand-tertiary;
  }


  .bg-fixed {
    background-attachment: fixed;
  }

  //For the We Make ev easy page

  .bg-black-alt {
    background-color: #000d1a !important;
  }

  //horizontally align content
  .align-content-horz {
    .wp-block-group__inner-container {
      border-top: 1px solid @brand-tertiary;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: start;
      padding: 1.5em 0 0 0;


      .is-layout-constrained .wp-block-group__inner-container {
        border-top: 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: start;
        padding: 0 0 0 1.5em;


        h3 {
          margin-bottom: .5em

        }

        p {
          font-size: .875em !important;
        }
      }
    }
  }


  // Logo Rows

  //.logos-row-small {
  //  .column {
  //    display: flex;
  //    align-items: center;
  //    flex-wrap: wrap;
  //    justify-content: center;
  //    @media (max-width: 1550px) {
  //      justify-content: center;
  //    }
  //
  //
  //    .logo-sizer {
  //      box-sizing: border-box;
  //      padding: 24px 0;
  //      width: 185px;
  //      margin-bottom: 0;
  //      @media (max-width: @screen-xs-max) {
  //        width: 64px;
  //      }
  //      @media (max-width: 1733px) {
  //        width: 175px;
  //      }
  //      @media (max-width: 1640px) {
  //        width: 170px;
  //      }
  //      @media (max-width: 1578px) {
  //        width: 165px;
  //      }
  //      @media (max-width: 1550px) {
  //        width: 185px;
  //      }
  //
  //    }
  //  }
  //}


  .is-style-img-row {
    width: 100%;


    .wp-block-group__inner-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      @media (max-width: @screen-sm-max) {
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .img-wrapper {
        width: 140px;
        @media (max-width: @screen-sm-max) {
        width: 64px;
        }
      }
    }
  }

  .is-style-img-row-alt {
    width: 100%;

    .wp-block-group__inner-container {
      display: flex;
      justify-content: center;
      @media (min-width: @screen-sm-min) {
        flex-wrap: nowrap;
      }

      .img-wrapper {
        max-width: 140px;
      }
    }
  }

  //Custom Image, Video and Icon Handling

  .shadow-green iframe {
    box-shadow: .75em .75em #004d40;
  }

  .icon-row {
    .img-icon-sm {
      padding: .75em 1.5em;
    }

    .img-icon-md {
      padding: .75em 1.5em;
    }
  }


  // Check marks li - We make EVs Easy Page

  .plus-green {
    li {
      padding-bottom: 0;
    }
  }


  .check-green {
    ul {
      li {
        padding-bottom: 0;

        &:before {
          content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cpath class='st0' style='fill: %2370D44B;' d='M8,12.8l3.9-12.6h2.8L9.6,16h-3l-2.3-5.7H1.7V7.8h4.7L8,12.8z'/%3E%3C/svg%3E%0A") !important;
          //font-family: 'FontAwesome';
          color: @brand-secondary;
          padding-right: .25em;
          padding-top: 0.05em;
        }
      }
    }

    strong {
      font-size: 1.5em;
    }
  }

  .check-green {
    ul {
      li {
        padding-bottom: .25em;
        font-size: 1rem !important;
        margin-bottom: .5em;
        line-height: 1.5em;

        &:before {
          content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cpath class='st0' style='fill: %2370D44B;' d='M8,12.8l3.9-12.6h2.8L9.6,16h-3l-2.3-5.7H1.7V7.8h4.7L8,12.8z'/%3E%3C/svg%3E%0A") !important;
          color: @brand-secondary;
          padding-right: 0.25em;
          padding-top: 0.05em;
          font-size: .9rem;
          //margin-top: 0.255em;
        }
      }
    }
  }


  ul {
    li {
      &:before {
        margin-left: -1.5em;
        display: inline-block;
        width: 1.1em;
        float: left;
      }
    }
  }


  //New Home Page
  .neg-margin-content {
    @media (min-width: @screen-md-min) {
      width: calc(100% + var(--var-pad) * 2);
      padding: 2em;
      &.left {
        margin-left: calc(-3 * var(--var-pad));
      }

      &.right {
        margin-right: calc(-3 * var(--var-pad));
        z-index: 100;
      }
    }
    @media (max-width: @screen-md-max) {
      margin-top: calc(-1.5 * var(--var-pad));
      padding: 1.5em;
    }
  }

  .neg-margin-img-top {

    @media (max-width: @screen-sm-max) {
      // padding: 0 6em;
    }
    @media (max-width: @screen-xs-max) {
      // padding: 0 2em;
    }
    @media (min-width: @screen-md-min) {
      margin-top: -4.5em;
      // padding: 0 2em;
    }
  }


  //Borders

  .border-horz-blue {
    @media (min-width: @screen-md-min) {
      border-left: 1px solid @brand-tertiary;
      border-right: 1px solid @brand-tertiary;
    }
    //@media (max-width: @screen-sm-max) {
    //  border-top: 1px solid @brand-tertiary;
    //  border-bottom: 1px solid @brand-tertiary;
    //}
  }

  .border-horz-blue-left {
    border-left: 1px solid @brand-tertiary;
    @media (max-width: @screen-sm-max) {
      border-left: 0;
    }
  }

  .border-horz-blue-right {
    border-right: 1px solid @brand-tertiary;
    @media (max-width: @screen-sm-max) {
      border-right: 0;
    }
  }


  .border-top-blue {
    border-top-width: 1em;
    border-top-style: solid;
    border-image: linear-gradient(to right, #002946, #00556d) 1;
  }

  .top-border-blue {
    .wp-block-group {
      border-top: 1px solid @brand-tertiary;
      padding: 1.5em 0;
      margin-bottom: 0;

      li {
        margin-bottom: 0;
      }
    }
  }

  .border-radius {
    @media (max-width: @screen-sm-max) {
      margin: 1.5em;
      border-radius: 1em;
    }
  }


  //I dont know what this is for

  @media (min-width: @screen-md-min) {
    .row.col-fifths {
      .column {
        width: 20%;
      }
    }
  }

  .image-margin-adjust {
    .column {
      margin-right: -0.75em;
      margin-left: -0.75em;
    }
  }


  //Custom Image Handling

  .neg-margin-bottom {
    margin-bottom: -1px;
  }

  .img-overflow {
    overflow: hidden;


    .column.img {

      position: relative;
      container: img-column / inline-size;
      @media (min-width: @screen-md-min) {
        overflow: visible;
        container: img-column / size;

        .img-wrapper {
          width: 100%;
          height: 100%;
          display: block;
        }

        picture {
          position: absolute;
          top: 0;
          width: auto;
          max-width: none;
          min-width: 50vw;
          height: 100cqh;
          float: right;
          display: block;

          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }

        &:nth-child(1) {
          picture {
            right: 0;
          }
        }

        &:nth-child(2) {
          picture {
            left: 0;
          }
        }

      }
      @media (max-width: @screen-xs-max) {
        picture {
          width: 100%;
          max-width: none;
          height: 100%;
          aspect-ratio: 7 / 4;

          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }
  }

  @media (min-width: @screen-md-min ) {
    .img-wrapper.software-picture {
      picture, img {
        object-fit: cover !important;
        width: 60vw !important;
        max-width: none !important;
        height: 100% !important;
        object-position: 50% 50% !important;
      }
    }
  }

  @media (max-width: @screen-sm-max) {
    .software-picture, .software-picture-main {
      picture {
        width: 100vw !important;
        margin: 0 calc(-50vw + 50%) !important;
      }
    }
  }

  @media (min-width: @screen-md-min ) {
    .img-wrapper.software-picture-main {
      picture, img {
        object-fit: cover !important;
        width: 50vw !important;
        max-width: none !important;
        height: 100% !important;
        object-position: 50% 50% !important;
      }
    }
  }

  @media (min-width: @screen-md-min ) {
    .img-wrapper.software-picture-info {
      picture, img {
        object-fit: cover !important;
        width: 40vw !important;
        max-width: none !important;
        height: 100% !important;
        object-position: 50% 50% !important;
      }
    }
  }
  // Button treatment
  a.btn-alternate.btn-primary span:before {
    content: '> ';
  }

  a.btn-alternate.btn-tertiary span:before {
    content: '> ';
  }

  //Team Members
  .team-members {
    h3 {
      strong {
        font-size: 1rem;
      }
    }

    .img-icon-sm {
      width: 30px;
    }
  }
}


//Table - We Make Ev Easy

.custom-table {
  table thead tr th {
    background: #00566e;
    color: #fff;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
  }

  table thead tr th:nth-of-type(1) {
    width: 70%;
    max-width: 800px;

  }

  table thead tr th:nth-of-type(2) {
    width: 15%;
    max-width: 120px;
    padding-left: 10px;
  }

  table thead tr th:nth-of-type(3) {
    width: 15%;
    max-width: 120px;
    background: @brand-secondary;
    padding-left: 10px;
  }

  table tbody tr:nth-of-type(even) td:nth-of-type(1) {
    background: #e6e8ea;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
  }

  table tbody tr:nth-of-type(odd) td:nth-of-type(1) {
    background: #f2f2f2;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
  }


  .check {
    text-align: center;
    color: @brand-secondary;
  }

  .minus {
    text-align: center

  }


  table tbody tr:nth-of-type(even) td {
    background: #e6e8ea;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
  }

  table tbody tr:nth-of-type(odd) td {
    background: #f2f2f2;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
  }
}

//Explore

:root {
  --servies-size: 1em;
}

.global-services {
  width: 100%;
  line-height: 1.25em;
  overflow: hidden;

  .wp-block-group {
    position: relative;
    padding: (@grid-gutter-width/2) (@grid-gutter-width/1.5);
    width: calc(100% / 2) !important;

    @media (min-width: @screen-sm-min) {
      width: calc(100% / 4) !important;
      padding: (@grid-gutter-width/2) 1em;
    }
    @media (min-width: @screen-xl-min) {
      padding: (@grid-gutter-width/2) (@grid-gutter-width/2.75);
      width: calc(100% / 7) !important;
    }

    .img-wrapper {
      width: 100%;
      height: 100%;
      display: block;
      margin-bottom: .375em !important;

      &.triangle-right-teal {
        position: absolute;
        top: 10%;
        left: 53%;
        z-index: -1;
        opacity: 0.15;
        @media (max-width: 1469px) {
          top: 13%;
          left: 41%;
        }
        @media (max-width: @screen-sm-max) {
          top: 21%;
          left: 48%;
        }
        @media (max-width: @screen-xs-max) {
          top: 10%;
          left: 33%;
        }

        img {
          width: 1.375em;
        }
      }
    }

    hr {
      margin: 0 !important;
    }

    em {
      font-style: normal;
      font-size: calc(var(--servies-size) * .75);

    }
  }
}

// Alternating Text Block

.alternating-text-padding {
  padding-right: 15em;
  @media (max-width: @screen-lg-max) {
    padding-right: 10em;
  }
  @media (max-width: @screen-md-max) {
    padding-right: 6em;
  }
  @media (max-width: @screen-sm-max) {
    padding-right: 5em;
  }
  @media (max-width: @screen-xs-max) {
    padding-right: 0;
  }
}

// List treatments special
#live-area {
  .list-plus {
    .column {
      //align-content: space-between;
    }

    li {
      padding-bottom: 0.25em;
      margin-bottom: 0;
    }

    &:before {
      color: @brand-primary;
    }
  }
}

// Paragraph treatments

.is-bg-black {
  p, li {
    color: #c9d1d7;
    transition: .3s ease;

    &:hover {
      transition: .3s ease;
      color: @brand-text-on-dark;
    }
  }
}

.is-bg-default {
  p, li {
    color: @brand-gray;

    &:hover {
      transition: .3s ease-in-out;
      color: #00556e;
    }
  }
}

.para-text-small {
  p {
    font-size: .875em !important;
  }
}

// XS Icon
#live-area .icon-xs .img-wrapper.img-icon-sm img {
  height: 45px !important;
}

// Image scroll
@media (min-width: @screen-md-min) {
  #live-area {
    .container-scroll-inner {
      display: flex;
      align-content: flex-start;
      overflow: visible !important;
      // background: rgba(0, 85, 110, .05);
      //height: 100cqh;
      max-width: 100%;
      // transition: .5s ease;
    }

    .img-scroll {
      //transition: .5s ease;
      position: sticky;
      top: 10%;
      margin-top: -8rem;
    }
  }
}

sup {
  font-size: 0.5em !important;
}

.wp-block-post-excerpt {
  a {
    color: @brand-tertiary;
  }
}


// Hexagons
.hexagon-container {
  .wp-block-group {
    &.hexagon {
      width: 222px !important;
      height: 222px;
      position: relative;
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      &:nth-child(5), &:nth-child(6) {
        @media screen and (min-width: 992px) and (max-width: 1199px) {
          margin: -3em auto !important;
        }
      }

      p {
        line-height: 1.2;
        font-style: italic !important;
        padding: 0 1.5em !important;
      }

      h3 {
        padding-top: 2.3em;
        margin-bottom: .25em;
      }

      &.red::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpolygon points='50,1 95,25 95,75 50,99 5,75 5,25' fill='none' stroke='%23FF4D00' stroke-width='' /%3E%3C/svg%3E");
      }

      &.green::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpolygon points='50,1 95,25 95,75 50,99 5,75 5,25' fill='none' stroke='%2370d44b' stroke-width='' /%3E%3C/svg%3E");
      }

      &.blue::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpolygon points='50,1 95,25 95,75 50,99 5,75 5,25' fill='none' stroke='%2349a5d9' stroke-width='' /%3E%3C/svg%3E");
      }
    }
  }
}

.cta-image {
  width: 100%;
  display: flex;
  justify-content: end;
}

#live-area .header-svg-box .wp-block-group__inner-container {
  display: flex !important;
  flex-wrap: wrap;
  h2 {
    display: flex;
    align-items: flex-start;
    width: auto;
    line-height: 1 !important;
    padding-right: 1rem;
    margin-bottom: .5rem !important;

    &.plan-svg:before {
      content: url('../images/FE_Process_Insight_Icon_POS_Full-Color.svg');
      display: inline-block;
      margin-right: .25em;
      width: 1.5em;
    }
    &.install-svg:before {
      content: url('../images/FE_Process_Integration_Icon_Full-Color.svg');
      display: inline-block;
      margin-right: .25em;
      width: 1.7em;
      margin-top: -.1375em;
    }
    &.manage-svg:before {
      content: url('../images/FE_Process_Interface_Icon_POS_Full-Color.svg');
      display: inline-block;
      margin-right: .25em;
      width: 1.5em;

    }
  }
}


