
#main-nav .navbar-collapse > div > ul > li > ul {
  border-bottom: none;
}

#main-nav .navbar-collapse > div > ul > li > ul li li a {
  border-top: none;
}

#mega-menu-wrap-navbar-main #mega-menu-navbar-main li.mega-menu-item > ul.mega-sub-menu {
  display: block;
  visibility: hidden;
  opacity: 1;
  pointer-events: auto;
}

#mega-menu-wrap-navbar-main #mega-menu-navbar-main li.mega-menu-item.mega-menu-megamenu ul.mega-sub-menu li.mega-collapse-children > ul.mega-sub-menu {
  display: none;
}

#mega-menu-wrap-navbar-main #mega-menu-navbar-main li.mega-menu-item.mega-menu-megamenu ul.mega-sub-menu li.mega-collapse-children.mega-toggle-on > ul.mega-sub-menu {
  display: block;
}

#mega-menu-wrap-navbar-main #mega-menu-navbar-main.mega-no-js li.mega-menu-item:hover > ul.mega-sub-menu, #mega-menu-wrap-navbar-main #mega-menu-navbar-main.mega-no-js li.mega-menu-item:focus > ul.mega-sub-menu, #mega-menu-wrap-navbar-main #mega-menu-navbar-main li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu {
  visibility: visible;
}

#mega-menu-wrap-navbar-main #mega-menu-navbar-main li.mega-menu-item.mega-menu-megamenu ul.mega-sub-menu ul.mega-sub-menu {
  visibility: inherit;
  opacity: 1;
  display: flex;

}


#mega-menu-wrap-navbar-main #mega-menu-navbar-main > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item.mega-menu-clear, #mega-menu-wrap-navbar-main #mega-menu-navbar-main > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item.mega-menu-clear {
  clear: left;
}


#main-nav {

  ul, li {
    list-style: none;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }

  .mega-menu-wrap {
    text-indent: 0;

    .mega-menu {
      position: relative;
      display: flex;
      justify-content: flex-start;
      visibility: visible;
      text-align: left;
      padding: 0;

      li {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;

        a {
          line-height: 1;
          display: inline-block;
          width: 100%;
          color: @brand-teal;
          padding: 0 2em 3em 0;
          //  transition: .2s ease-in;
          &:hover, &:focus, &:active {
            background: transparent !important;
            color: @brand-black;
          }
        }
      }

      > li.mega-menu-item {

        position: static;

        &.mega-menu-item, li.mega-menu-item {
          position: static;

          &.mega-menu-flyout {
            position: relative;

            .mega-sub-menu {
              background: transparent !important;
              overflow: hidden;

              a.mega-menu-link {
                color: @brand-text-on-dark;
                background: rgba(0, 34, 63, 0.8) !important;
                padding: 1.5em;
                border-top: none;
                border-bottom: 1px solid rgb(0, 62, 114);
                border-right: 1px solid rgb(0, 62, 114);
                border-left: 1px solid rgb(0, 62, 114);

                &:hover {
                  background: rgba(0, 62, 114, 1) !important;
                }
              }
            }
          }

          &.mega-menu-flyout.mega-wpml-ls-item {
            .mega-sub-menu {
              text-align: center;
              width: 100%;
              left: 0;
            }

            .mega-indicator {
              display: none !important;
            }
          }
        }

        font-family: @main-nav-family;
        font-weight: @main-nav-fweight;
        font-style: @main-nav-fstyle;
        text-transform: @main-nav-ftransform;
        height: @main-nav-height;
        line-height: 1;
        display: flex;
        align-items: center;

        @media (max-width: @screen-md-max) {
          line-height: @main-nav-adjusted-height;
        }

        > a.mega-menu-link {
          position: relative;
          padding: @main-nav-top-level-padding;
          box-sizing: border-box;
          vertical-align: baseline;
          width: auto;
          display: block;
          color: @brand-text-on-dark;
          text-decoration: none;
          font-family: @main-nav-family;
          font-size: @main-nav-fsize;
          font-weight: @main-nav-fweight;
        }

        > ul.mega-sub-menu {
          padding-left: 0;
          position: absolute;
          left: 0%;
          top: (@grid-gutter-width * 1.45);
          margin: 0;
          width: 100%;
          max-width: none;
          opacity: 0;
          z-index: 10;
          background: @brand-text-on-dark !important;
          box-shadow: rgba(0, 34, 63, 0.2) 0px 10px 10px -10px;
          //  transition: .2s ease-in;
          transform: translate(0, 10px);
        }
      }

      > li.mega-menu-megamenu {
        > ul.mega-sub-menu {
          li.mega-menu-column {
            > ul.mega-sub-menu {
              > li.mega-menu-item {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  li {
    &.mega-menu-row {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: row;
      padding: 4em calc((100vw - @container-xlarge-desktop) / 2);
      @media (max-width: @screen-lg-max) {
        padding: 4em calc((100vw - @container-large-desktop) / 2);
      }
      @media (max-width: @screen-md-max) {
        padding: 4em calc((100vw - @container-md-reduced) / 4);
      }

      ul.mega-sub-menu {
        padding: 0;

        ul.mega-sub-menu {
          padding: 0 2em;
          visibility: inherit;
          opacity: 1;
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          justify-content: flex-start;
          flex-direction: row;
        }

        .main-link-card {
          a {
            padding: 0;
            text-indent: 0;

            h3 {
              padding-bottom: .5rem;
              margin-bottom: .5em;
              font-size: 1.675em;
              font-style: italic;
            }


            p {
              margin-top: 0;
              font-weight: 400;
              text-indent: 0;
              line-height: 1.4;
              font-size: 12px;
            }
          }
        }
      }
    }

    &.mega-menu-item {
      &.mega-menu-item-has-children {
        > a {
          &.mega-menu-link {
            > span.mega-indicator {
              display: inline-block;

              &:after {
                content: '\f140';
                font-family: dashicons;
                font-weight: normal;
                display: inline-block;
                margin: 0;
                vertical-align: top;
                transform: rotate(0);
                color: inherit;
                position: relative;
                background: transparent;
                height: auto;
                width: auto;
                right: auto;
                line-height: inherit;
              }
            }
          }
        }
      }
    }


    &.mega-menu-column {
      float: left;
      display: inline-block;

      .mega-menu-item-has-children {
        .mega-sub-menu {
          .mega-menu-item {
            .mega-menu-link {
              padding: (@grid-gutter-width /2) 0;
            }
          }
        }
      }
    }

    &.mega-menu-column:first-child {
      border-right: 1px solid #00556e;
    }

    .mega-has-description {
      width: 25%;
      @media (max-width: @screen-md-max) {
        width: 50%;
      }
      padding: (@grid-gutter-width / 2)  0;

      a.mega-menu-link {
        .mega-description-group {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: baseline;
          transition: none;
          text-transform: initial;
          padding: 0 0 0 0;

          .mega-menu-title {
            padding-bottom: .5em;
            margin-bottom: .5em;
            font-size: 1rem;
            font-style: italic;
            transition: none;
            line-height: 1;
            text-indent: 0;
          }

          .mega-menu-description {
            font-style: inherit;
            font-weight: normal;
            font-size: 12px;
            transition: none;
            text-indent: 0;
            line-height: 1.4;
          }
        }
      }
    }
  }
}


.mega-menu-toggle {
  display: none;
}


li.mega-menu-columns-1-of-1 {
  width: 100%;
}

li.mega-menu-columns-1-of-2 {
  width: 50%;
}

li.mega-menu-columns-2-of-2 {
  width: 100%;
}

li.mega-menu-columns-1-of-3 {
  width: 33.33333%;
}

li.mega-menu-columns-2-of-3 {
  width: 66.66667%;
}

li.mega-menu-columns-3-of-3 {
  width: 100%;
}

li.mega-menu-columns-1-of-4 {
  width: 25%;
}

li.mega-menu-columns-2-of-4 {
  width: 50%;
}

li.mega-menu-columns-3-of-4 {
  width: 75%;
}

li.mega-menu-columns-4-of-4 {
  width: 100%;
}

li.mega-menu-columns-1-of-5 {
  width: 20%;
}

li.mega-menu-columns-2-of-5 {
  width: 40%;
}

li.mega-menu-columns-3-of-5 {
  width: 60%;
}

li.mega-menu-columns-4-of-5 {
  width: 80%;
}

li.mega-menu-columns-5-of-5 {
  width: 100%;
}

li.mega-menu-columns-1-of-6 {
  width: 16.66667%;
}

li.mega-menu-columns-2-of-6 {
  width: 33.33333%;
}

li.mega-menu-columns-3-of-6 {
  width: 50%;
}

li.mega-menu-columns-4-of-6 {
  width: 66.66667%;
}

li.mega-menu-columns-5-of-6 {
  width: 83.33333%;
}

li.mega-menu-columns-6-of-6 {
  width: 100%;
}

li.mega-menu-columns-1-of-7 {
  width: 14.28571%;
}

li.mega-menu-columns-2-of-7 {
  width: 28.57143%;
}

li.mega-menu-columns-3-of-7 {
  width: 42.85714%;
}

li.mega-menu-columns-4-of-7 {
  width: 57.14286%;
}

li.mega-menu-columns-5-of-7 {
  width: 71.42857%;
}

li.mega-menu-columns-6-of-7 {
  width: 85.71429%;
}

li.mega-menu-columns-7-of-7 {
  width: 100%;
}

li.mega-menu-columns-1-of-8 {
  width: 12.5%;
}

li.mega-menu-columns-2-of-8 {
  width: 25%;
}

li.mega-menu-columns-3-of-8 {
  width: 37.5%;
}

li.mega-menu-columns-4-of-8 {
  width: 50%;
}

li.mega-menu-columns-5-of-8 {
  width: 62.5%;
}

li.mega-menu-columns-6-of-8 {
  width: 75%;
}

li.mega-menu-columns-7-of-8 {
  width: 87.5%;
}

li.mega-menu-columns-8-of-8 {
  width: 100%;
}

li.mega-menu-columns-1-of-9 {
  width: 11.11111%;
}

li.mega-menu-columns-2-of-9 {
  width: 22.22222%;
}

li.mega-menu-columns-3-of-9 {
  width: 33.33333%;
}

li.mega-menu-columns-4-of-9 {
  width: 44.44444%;
}

li.mega-menu-columns-5-of-9 {
  width: 55.55556%;
}

li.mega-menu-columns-6-of-9 {
  width: 66.66667%;
}

li.mega-menu-columns-7-of-9 {
  width: 77.77778%;
}

li.mega-menu-columns-8-of-9 {
  width: 88.88889%;
}

li.mega-menu-columns-9-of-9 {
  width: 100%;
}

li.mega-menu-columns-1-of-10 {
  width: 10%;
}

li.mega-menu-columns-2-of-10 {
  width: 20%;
}

li.mega-menu-columns-3-of-10 {
  width: 30%;
}

li.mega-menu-columns-4-of-10 {
  width: 40%;
}

li.mega-menu-columns-5-of-10 {
  width: 50%;
}

li.mega-menu-columns-6-of-10 {
  width: 60%;
}

li.mega-menu-columns-7-of-10 {
  width: 70%;
}

li.mega-menu-columns-8-of-10 {
  width: 80%;
}

li.mega-menu-columns-9-of-10 {
  width: 90%;
}

li.mega-menu-columns-10-of-10 {
  width: 100%;
}

li.mega-menu-columns-1-of-11 {
  width: 9.09091%;
}

li.mega-menu-columns-2-of-11 {
  width: 18.18182%;
}

li.mega-menu-columns-3-of-11 {
  width: 27.27273%;
}

li.mega-menu-columns-4-of-11 {
  width: 36.36364%;
}

li.mega-menu-columns-5-of-11 {
  width: 45.45455%;
}

li.mega-menu-columns-6-of-11 {
  width: 54.54545%;
}

li.mega-menu-columns-7-of-11 {
  width: 63.63636%;
}

li.mega-menu-columns-8-of-11 {
  width: 72.72727%;
}

li.mega-menu-columns-9-of-11 {
  width: 81.81818%;
}

li.mega-menu-columns-10-of-11 {
  width: 90.90909%;
}

li.mega-menu-columns-11-of-11 {
  width: 100%;
}

li.mega-menu-columns-1-of-12 {
  width: 8.33333%;
}

li.mega-menu-columns-2-of-12 {
  width: 16.66667%;
}

li.mega-menu-columns-3-of-12 {
  width: 25%;
}

li.mega-menu-columns-4-of-12 {
  width: 33.33333%;
}

li.mega-menu-columns-5-of-12 {
  width: 41.66667%;
}

li.mega-menu-columns-6-of-12 {
  width: 50%;
}

li.mega-menu-columns-7-of-12 {
  width: 58.33333%;
}

li.mega-menu-columns-8-of-12 {
  width: 66.66667%;
}

li.mega-menu-columns-9-of-12 {
  width: 75%;
}

li.mega-menu-columns-10-of-12 {
  width: 83.33333%;
}

li.mega-menu-columns-11-of-12 {
  width: 91.66667%;
}

li.mega-menu-columns-12-of-12 {
  width: 100%;
}


div.content-navigation-links {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  text-align: center;
  background: #fff;
  transform: translateY(-100px);
  z-index: 99999;

  &:focus-within {
    transform: translateY(0);
  }
}

i.logo {
  display: inline-block;
  width: 1.5em;

  &.initiative:before {
    content: url('../images/FE_Process_Initiative_Icon_POS_Full-Color.svg');

  }

  &.interface:before {
    content: url('../images/FE_Process_Interface_Icon_POS_Full-Color.svg');

  }

  &.integration:before {
    content: url('../images/FE_Process_Integration_Icon_Full-Color.svg');

  }

  &.instruct:before {
    content: url('../images/FE_Process_Instruct_Icon_POS_Full-Color.svg');

  }

  &.inspect:before {
    content: url('../images/FE_Process_Inspect_Icon_POS_Full-Color.svg');

  }

  &.insight:before {
    content: url('../images/FE_Process_Insight_Icon_POS_Full-Color.svg');

  }

  &.infrastructure:before {
    content: url('../images/FE_Process_Infrasctructure_Icon_POS_Full-Color.svg');

  }

  &.dealership:before {
    content: url('../images/FE_Vector_Master_2023_Industries_AutoDealers_Icon.svg');

  }

  &.fleet:before {
    content: url('../images/FE_Vector_Master_2023_Industries_Fleet_Icon.svg');
  }

  &.healthcare:before {
    content: url('../images/FE_Vector_Master_2023_Industries_Healtchare_Icon.svg');
  }

  &.hotel:before {
    content: url('../images/FE_Vector_Master_2023_Industries_Hospital_Icon.svg');
  }

  &.car:before {
    content: url('../images/FE_Vector_Master_2023_Solution_Vehicle_Mgmt_Icon.svg');
  }

  &.charging:before {
    content: url('../images/FE_Vector_Master_2023_Solutions_EV_Charging_Icon.svg');
  }

  &.lighting:before {
    content: url('../images/FE_Vector_Master_2023_Solutions_Lighting_Icon.svg');
  }

  &.energy:before {
    content: url('../images/FE_Vector_Master_2023_Solutions_SmartEnergy_Icon.svg');
  }

  &.usa:before {
    content:url('../images/usa_flag.svg');
  }


}

i.logox {
  display: inline-block;
  width: 2em;
  &.canada:before {
    content: url('../images/canadian-flag.svg');
  }
}

#main-nav li.mega-menu-item.mega-menu-item-has-children > a.mega-menu-link > .usa + span.mega-indicator {
  display: none;
}
