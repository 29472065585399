footer {
  display: block;
  float: left;
  position: relative;
  width: 100%;
  @media ( min-width: @screen-lg-min) {
    .logo-container {
      //width: auto;
    }
  }

  a {
    line-height: 1;
    color: @brand-text-on-dark!important;
    font-weight: 400 !important;
    border-bottom: 2px solid transparent !important;

    &:hover {
      color: @brand-text-on-dark !important;
      border-bottom: 2px solid @brand-tertiary !important;
    }
  }
  .row {
    padding-bottom: 0 !important;
  }

.form-align {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  @media (max-width: @screen-sm-max) {
    text-align: left;
    justify-content: flex-start;
  }
}
  .center-footer {
    background: #000d1a;
  }

  .sub-logo {
    font-family: @headings-font-family;
    font-weight: 400;
    color: @brand-gray-light;
    @media (min-width: @screen-sm-min) {

      p {
        padding-left: (@grid-gutter-width * .25);

      }
    }
    @media (max-width: @screen-sm-min) {
      //margin-bottom: 1em;
      width: 300px;
    }
  }

  @media ( max-width: @screen-sm-min) {
    .logo-row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    .logo-container {
      //text-align: center;
      float: left;
      width: 100%;
      margin-bottom: 1em;
      width: 300px;
    }
  }

  img.inline-logo {
    width: 100%;
    max-width: 250px;
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);
  }

  h4 {
    //margin-top: .25em;
    color: @brand-text-on-dark;
   margin-bottom: .25em;
    text-transform: revert;
  }

  h3 a:hover {
    color: @brand-text-on-dark;
    border-bottom: 2px solid @brand-tertiary !important;
  }
h3.is-style-heading-h4 {
  margin-bottom: .5em;
}
  ul {

    padding-left: 0;
    list-style: none;
    margin-bottom: 0px;

    > li {
      display: inline-block;
      width: 100%;

      > a {
        //font-family: @headings-font-family;
        //font-weight: @headings-font-weight;
        font-family: @headings-font-family;
        font-weight: 700;
        color: @brand-text-on-dark;
        display: inline-flex;
        align-items: center;
        margin-bottom: .5em;
        font-size: 1.125em;
        border-bottom: 2px solid transparent!important;

        &:hover {
          color: @brand-text-on-dark;
          border-bottom: 2px solid @brand-tertiary !important;
        }
      }

      li {
        //padding-left: 1em;

        a {
          text-transform: none;
          font-size: 1em;
          //font-family: @headings-font-family;
          //font-weight: @headings-font-weight;
          font-family: @headings-font-family;
          font-weight: 400;
          color: @brand-text-on-dark;
          display: inline-flex;
          align-items: center;
          margin-bottom: .5em;

          &:before {
            content: '+';
            //color: @brand-secondary !important;
            display: inline-block;
            color: @brand-primary;
            width: 1em;
            margin-top: -0.125em;
            float: left;

          }
        }

      }
    }
  }

  .row {
    @media (max-width: @screen-md-min) {
      padding-bottom: 2em;
      //text-align: center;
      h1, h2, h3, h4, ul {
        text-align: left;
      }

      &.footer-widget-area {

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        > div {
          width: 33%;
        }
      }
    }
    @media (max-width: @screen-sm-min) {


      &.footer-widget-area {
        > div {
          margin-bottom: 1em;
          width: 300px;
        }
      }
    }
  }
p {
  margin-top: 0;
}
  .menu-row {
    color: @brand-text-on-dark;
    font-family: @headings-font-family;
    font-size: .875em;
    font-weight: 400;


    p {
      margin: 0 0 0 0;
      width: auto;
      display: inline-block;

    }

    ul.menu {
      padding-left: 1em;
      padding-right: 1em;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        width: auto;
        padding: 0 0 0 0;

        a {
          padding: 0 1em;
          border-left: 1px solid @brand-text-on-dark;
          margin: 0 0 0 0;

          &:before {
            display: none;
          }

        }
      }
    }
  }

  .footer-widget-area {
    padding-top: 1em;

    .footer-btn-special {
      a {
        .btn();
        .btn-primary();

        background: @brand-primary;
        color: #222f39;

        &:hover {
          background: #222f39;
          color: @brand-primary;

        }
      }
    }
  }
}

footer, #cta-modal {
  .pipe-spacer {
    color: @brand-primary;
  }
}

form {
  @media (max-width: @screen-sm-max) {
    text-align: left;
  }
}
footer {
  ul {
    @local-indent: @grid-gutter-width;

    &.is-style-inline-list-slash {
      width: 100%;
      display: inline-flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 0;

      li {
        width: auto !important;
        margin: 0 0 0 0 !important;
        padding: 0 0 0 0 !important;
        margin-bottom: .25em;
        font-size: .9em;
        color: @brand-text-on-dark!important;
        font-weight: 400 !important;


        a {
          line-height: 1;
          color: @brand-text-on-dark!important;
          font-weight: 400 !important;
          border-bottom: 2px solid transparent !important;

          &:hover {
            color: @brand-text-on-dark !important;
            border-bottom: 2px solid @brand-tertiary !important;
          }
        }

        &:before {
          content: '' !important;
          width: 0 !important;
          min-width: 0 !important;
        }

        &:after {
          content: '|';
          font-size: 1.25em;
          margin-left: .5em;
          margin-right: .5em;
          color: @brand-tertiary;
        }

        &:last-child:after {
          content: '';
        }
      }
    }
  }
}

.pipe-teal strong {
  color: @brand-tertiary !important;
  font-weight: 400;
}

