#live-area {
  p,
  ol,
  table,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: 100%;
  }

  p,
  ol,
  table,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  address,
  iframe,
  img,
  picture,
  a,
  audio,
  canvas,
  progress,
  video,
  b,
  strong,
  dfn,
  mark,
  small,
  sub,
  sup,
  svg,
  figure,
  code,
  kbd,
  pre,
  samp {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }

    &.align-inline {
      width: auto;
      margin-right: (@grid-gutter-width * @paragraph-margin-factor);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

#live-area {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    line-height: @headings-line-height;
    text-transform: @heading-text-transform;
    margin-top: @headings-vertical-adjust;

    &.text-xl, &.text-lg, &.text-xxl {
      line-height: @headings-line-height;
    }
  }

}

.column {
  p,
  ol,
  table,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: 100%;
  }
}

strong {
  font-weight: @strong-weight;

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    font-weight: @strong-headings-weight;
  }

}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  line-height: @headings-line-height;
  text-transform: @heading-text-transform;
  margin-top: @headings-vertical-adjust;
  letter-spacing: @letter-spacing-correction;
  margin-bottom: (@grid-gutter-width * @heading-margin-factor);
  margin-left: @tex-indent-correction;

  &.text-xl, &.text-lg, &.text-xxl {
    line-height: @headings-line-height;
  }

  &:only-child {
    margin-bottom: 0;
  }

  span {
    color: @brand-primary;
  }

  strong {
    //color: @brand-primary;
    font-weight: @strong-headings-weight;
  }
}

.column {
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

p {
  + h1, + .h1,
  + h2, + .h2,
  + h3, + .h3,
  + h4, + .h4,
  + h5, + .h5,
  + h6, + .h6 {
    //margin-top: (@grid-gutter-width * @paragraph-margin-factor);
  }
}

p {
  display: inline-block;
  margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);

  &:empty {
    display: none !important;
  }

  + ul, + ol {
    //margin-top: (@grid-gutter-width / @paragraph-margin-factor * -.5);
  }
}


.img-wrapper, a.img-wrapper-link,
#live-area .img-wrapper, #live-area a.img-wrapper-link {
  margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);
  max-width: 100%;
  width: 100%;
  display: inline-block;

  &:last-child {
    margin-bottom: 0;
  }

  img, picture, picture img {
    max-width: 100%;
    //width: auto;
  }

  &.img-inline {
    width: auto;
    display: inline-block;

    img, picture, picture img {
      display: inline-block;
    }
  }

  &.img-contain,
  &.img-cover {
    width: 100%;
  }

  &.img-cover {
    img, picture, picture img {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
      max-width: none;
    }
  }

  &.img-contain {
    img, picture, picture img {
      object-fit: contain;
      object-position: center center;
      width: 100%;
      height: 100%;
      max-width: none;
    }
  }

  &.img-icon-sm {
    display: inline-block;
  }

  &.img-icon-sm {
    img, picture, picture img {
      display: inline-block;
      height: 60px;
      width: auto;
    }
  }

  &.img-icon-md {
    display: inline-block;
  }

  &.img-icon-md {
    img, picture, picture img {
      display: inline-block;
      height: 80px;
      width: auto;
    }
  }

  &.img-icon-lg {
    width: auto;
    display: inline-block;
  }

  &.img-icon-lg {
    img, picture, picture img {
      display: inline-block;
      height: 120px;
      width: auto;
    }
  }
}

img, #live-area img {
  &.img-cover {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    max-width: none;
  }

  &.img-contain {
    object-fit: contain;
    object-position: center center;
    width: 100%;
    height: 100%;
    max-width: none;
  }

  &.img-icon-sm {
    width: 60px;
    display: inline-block;
  }

  &.img-icon-md {
    width: 120px;
    display: inline-block;
  }

  &.img-icon-lg {
    width: 180px;
    display: inline-block;
  }
}

#live-area, #content {

  a.img-wrapper-link:last-child,
  .image-wrapper:last-child {
    margin-bottom: 0;
  }

  p {
    + ul, + ol {
      //margin-top: (@grid-gutter-width / @paragraph-margin-factor * -.5);
    }
  }
}


i.fa, i.b-icon, span.b-icon {
  //color: @brand-primary;
}

a {
  i.fa, i.b-icon, span.b-icon {
    margin-bottom: 0px;
    margin-right: .25em;
  }
}

i.fa, .b-icon {
  margin-bottom: (@grid-gutter-width * @heading-margin-factor);

  &.h-icon {
    margin-bottom: 0;
    margin-right: .25em;
    vertical-align: bottom;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.btn {
  i, span.b-icon {
    color: inherit;
  }
}

.btn {
  margin-bottom: (@grid-gutter-width * @paragraph-margin-factor );
  font-weight: @btn-font-weight;

  &:last-child {
    margin-bottom: 0;
  }

}

#live-area {
  ul, ol {
    font-size: @font-size-base;
    line-height: @line-height-base;
    //display: inline-block;
    padding: 0 0 0 0;
    width: 100%;
    //float: left;
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;

    ul, ol {
      margin-bottom: 0;
    }

    li {
      //display: inline-block;
      width: 100%;
      padding-left: 1.5em;
      //padding-top: .5em;
      padding-bottom: (@grid-gutter-width * @paragraph-margin-factor / 6);
      margin-bottom: (@grid-gutter-width * @paragraph-margin-factor / 6);

      &:last-child {
        margin-bottom: 0;
      }

      li {
        margin-bottom: (@grid-gutter-width * @paragraph-margin-factor / 3);

        &:last-child {
          margin-bottom: 0;
        }

        li {
          margin-bottom: (@grid-gutter-width * @paragraph-margin-factor / 3);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &:before {
        display: inline-block;
        min-width: 1.5em;
        width: auto;
        padding-right: 1em;
        margin-left: -1.5em;
        color: @brand-black;
      }
    }
  }

  ul {
    li {
      &:before {
        content: '+';
      }
    }
  }

  ol {
    counter-reset: section;
    list-style-type: none;

    li {
      &:before {

        counter-increment: section;
        content: counters(section, ".");
      }
    }
  }

}

#live-area {
  .link-list {
    ul, ol {
      li a {
        color: inherit;

      }
    }
  }

}

#live-area {
  ul, ol {
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor )
  }


  blockquote {
    padding: (@line-height-computed / 2) @line-height-computed;

    margin-bottom: @line-height-computed;
    font-size: @blockquote-font-size;
    border-left: 5px solid @blockquote-border-color;

    p {
      &:last-child {
        font-size: .75em;
        font-style: italic;

        &:only-child {
          font-size: 1em;
          font-style: normal;
        }
      }
    }
  }
}

.column {
  hr, hr.wp-block-separator, hr.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
    width: 100%;
    margin-bottom: ( @grid-gutter-width * @paragraph-margin-factor );

    &:only-child {
      margin-bottom: 0;
    }

    border: 0;
    height: auto;
    display: inline-block;
    text-align: inherit;

    &:before {
      content: '';
      width: 100%;
      height: 0px;
      border-top: 1px solid;
      display: inline-block;
      float: none;
      line-height: 0;
      margin: 0 0 0 0;
      vertical-align: middle;
      max-width: 100%;
    }

    &.is-style-gradient-separator:before {
      height: 0.125em;
      border: 0px solid;
      background: linear-gradient(to right, @brand-primary, @brand-secondary, @brand-tertiary);
    }

    &.is-style-thin-teal-separator:before {
      height: 0.05em;
      border: 0px solid;
      background: @brand-tertiary;
    }

    &.is-style-thick-gradient-separator:before {
      height: 0.5em;
      border: 0px solid;
      background: linear-gradient(to right, @brand-primary, @brand-secondary, @brand-tertiary);
    }

    &.is-style-sm-teal-separator:before {
      height: 0.125em;
      border: 0px solid;
      width: 6em;
      background: @brand-tertiary;

    }

    &.is-style-sm-gradient-separator:before {
      height: 0.325em;
      border: 0px solid;
      width: 6em;
      background: linear-gradient(to right, @brand-primary, @brand-secondary, @brand-tertiary)
    }
  }
}

.column {
  p, h1, h2, h3, h4, h5, h6, ul, ol {
    + hr, + hr.wp-block-separator, + hr.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
      margin-top: 0;
    }
  }
}

.column > hr {
  &:only-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

p, h1, h2, h3, h4, h5, h6, ul, ol {
  + hr {
    margin-top: 0;
  }
}

iframe {
  max-width: 100%;
  width: 100%;
  display: inline-block;
}

.next-prev-links {
  border-top: 1px solid @text-color;

  a.btn {
    margin: 20px 0 20px 0;
    display: inline-block;
  }

}

// --------------------------------------------------------------------------------------------------------------------
// Type and heading Size Adjustments
// --------------------------------------------------------------------------------------------------------------------

.size-iterator(@n, @i: 2) when (@i =< @n) {
  .text-@{i}x {
    font-size: unit(@i, em);
    line-height: @line-height-base;
    .headings-line-height-reset(1);
  }
  .size-iterator(@n, (@i + 1));
}

.size-iterator(5);

///////////////////////// ------------------------------------------------------------------------------------

.headings-line-height-reset(@size-factor) {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    line-height: @headings-line-height;

    &.text-xl, &.text-xxl, &.text-lg {
      line-height: @headings-line-height;
    }

  }
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-bottom: ((@grid-gutter-width * @heading-margin-factor) * @size-factor);

    &:only-child {
      margin-bottom: 0;
    }

  }
  p {
    + h1, + .h1,
    + h2, + .h2,
    + h3, + .h3,
    + h4, + .h4,
    + h5, + .h5,
    + h6, + .h6 {
      //margin-top: ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);
    }

    + ul, + ol {
      //margin-top: (@grid-gutter-width / @paragraph-margin-factor * @size-factor * -.5 );
    }
  }
  ul, ol {
    li {
      margin-bottom: ((@grid-gutter-width * @paragraph-margin-factor / 3) * @size-factor);

      li {
        margin-bottom: ((@grid-gutter-width * @paragraph-margin-factor / 3) * @size-factor);

        &:last-child {
          margin-bottom: 0;
        }

        li {
          margin-bottom: ((@grid-gutter-width * @paragraph-margin-factor / 3) * @size-factor);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .btn {
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor );

    &:last-child {
      margin-bottom: 0px;
    }
  }
  p {
    margin-bottom: ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);

    &:empty {
      display: none !important;
    }
  }
  i.fa, .b-icon {
    margin-bottom: ((@grid-gutter-width * @heading-margin-factor) * @size-factor);

    &.h-icon {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .img-wrapper {
    margin-bottom: ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);

    &:last-child {
      margin-bottom: 0;
    }
  }
  #live-area {
    ul, ol {
      margin-bottom: ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);

      ul, ol {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
  hr {
    margin-top: ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);
    margin-bottom: ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);
    border: 0;
    border-top: 1px solid @text-color;
  }

  p, h1, h2, h3, h4, h5, h6, ul, ol {
    + hr {
      margin-top: 0;
    }
  }

}

.text-lg, .has-lg-font-size {
  @size: @text-large;
  @local-size: unit(@size, em);
  .headings-line-height-reset(@text-large);
  font-size: @local-size;
  line-height: (@line-height-base);

  @media (max-width: @screen-md-max) {
    @size: (@text-large );
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }
  @media (max-width: @screen-sm-max) {
    @size: (@text-large - 0.125 );
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }

  @media (max-width: @screen-xs-max) {
    @size: (@text-large - 0.125 - 0.125 );
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }
  @media (max-width: @screen-xs-min) {
    @size: 1;
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }
}

.text-xl, .has-xl-font-size {
  @size: @text-xlarge;
  @local-size: unit(@size, em);
  .headings-line-height-reset(@text-xlarge);
  font-size: @local-size;
  line-height: (@line-height-base);
  @media (max-width: @screen-md-max) {
    @size: (@text-xlarge - .125);
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }

  @media (max-width: @screen-sm-max) {
    @size: (@text-xlarge - 0.25);
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }

  @media (max-width: @screen-xs-max) {
    @size: (@text-xlarge - .375);
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }

  @media (max-width: @screen-xs-min) {
    @size: 1;
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }
}

.is-style-heading-h1 {
  font-size: @font-size-h1;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.is-style-heading-h2 {
  font-size: @font-size-h2;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.is-style-heading-h3 {
  font-size: @font-size-h3;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.is-style-heading-h4 {
  font-size: @font-size-h4;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.is-style-heading-h5 {
  font-size: @font-size-h5;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.is-style-heading-h6 {
  font-size: @font-size-h6;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.is-style-no-heading {
  font-size: 0 !important;
  margin: 0 0 0 0 !important;
}


.text-reset {
  font-size: @base-em-size;
  line-height: @line-height-base;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }

  .headings-line-height-reset(1);
}

#live-area, #content {
  section .row.icon-block {
    .img-wrapper {
      width: 100%;
      text-align: inherit;

      img, picture, picture img {
        display: inline-block;
        max-width: (@grid-gutter-width * 3);
      }
    }
  }

  section .row.logos-row {
    .column {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }

    .img-wrapper {
      box-sizing: border-box;
      padding: (@grid-gutter-width/2) (@grid-gutter-width/4);
      width: 220px;
      margin-bottom: 0px;

      img, picture, picture img {
        margin-bottom: 0px;
      }
    }
  }
}

.note {
  padding: 1rem;
  color: #fff;
  font-size: 1rem !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  animation: colorchange 10s infinite;
  margin-bottom: 1rem;
}

.note p:before {
  content: "[ ";
}

.note p:after {
  content: " ]";
}

.note {
  display: none !important;

  *:last-child {
    margin-bottom: 0 !important;
  }
}

body.logged-in.role-administrator {
  span.highlighted-copy {
    display: inline-block;
    background: yellow;
    color: black;
  }

  .note {
    width: 100%;
    display: inline-block !important;
  }
}


.text-objects(@color;) {
  h1, h2, h3, h4, h5, h6, blockquote, address, li, span, strong, p {
    color: @color;
  }
}

.text-colors() {
  &.text-muted, .text-muted {
    color: @text-muted;
    .text-objects(@text-muted);
  }
  &.text-primary, .text-primary {
    color: @brand-primary;
    .text-objects(@brand-primary);
  }
  &.text-secondary, .text-secondary {
    color: @brand-secondary;
    .text-objects(@brand-secondary);
  }
  &.text-tertiary, .text-tertiary {
    color: @brand-tertiary;
    .text-objects(@brand-tertiary);
  }
  &.text-reset, .text-reset {
    color: inherit;
    .text-objects(inherit);
  }
}

#live-area {
  .text-colors();

  h1, h2, h3, h4, h5, h6, blockquote, address, li, span, strong, p {
    .text-colors();
  }
}


.has-primary-color {
  color: @brand-primary;
}

.has-secondary-color {
  color: @brand-secondary;
}

.has-tertiary-color {
  color: @brand-tertiary;
}

.has-black-color {
  color: @brand-black;
}

.has-gray-dark-color {
  color: @brand-gray-dark;
}

.has-gray-color {
  color: @brand-gray;
}

.has-gray-light-color {
  color: @brand-gray-light;
}

.has-default-color {
  color: @brand-text-on-dark;
}


.has-primary-background-color {
  background-color: @brand-primary;
}

.has-secondary-background-color {
  background-color: @brand-secondary;
}

.has-tertiary-background-color {
  background-color: @brand-tertiary;
}

.has-black-background-color {
  background-color: @brand-black;
}

.has-gray-dark-background-color {
  background-color: @brand-gray-dark;
}

.has-gray-background-color {
  background-color: @brand-gray;
}

.has-gray-light-background-color {
  background-color: @brand-gray-light;
}

.has-default-background-color {
  background-color: @brand-text-on-dark;
}


@table-header-bg: @brand-black;
@table-header-color: @brand-text-on-dark;
.wp-block-table, #live-area .wp-block-table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);

  &:last-child {
    margin-bottom: 0;
  }

  table {
    border: 1px solid;
    display: inline-table;
    border-collapse: collapse;
    width: auto;

    td, th {
      padding: .5em 1em;
      border: 1px solid;
    }

    thead, tfoot {
      border: 0;
      background: @table-header-bg;
      color: @table-header-color;
    }

    tbody {
      tr {
        &:nth-child(even) {
          background: @brand-gray-light;
        }

        td {
          border: 1px solid @brand-black;
        }
      }
    }

  }
}

ul.is-style-quad-list, #live-area ul.is-style-quad-list {
  display: flex;
  flex-wrap: wrap;
  align-items: start;

  li {
    width: 25%;
  }

  @media (max-width: @screen-md-min) {
    li {
      width: 100%;
    }
  }
}

ul.is-style-dual-list, #live-area ul.is-style-dual-list {
  display: flex;
  flex-wrap: wrap;
  align-items: start;

  li {
    width: 50%;
  }

  @media (max-width: @screen-md-min) {
    li {
      width: 100%;
    }
  }
}

#live-area ul.is-style-pro-list {
  li {
    width: 100%;

    &:before {
      content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cpath class='st0' style='fill: %2370D44B;' d='M8,12.8l3.9-12.6h2.8L9.6,16h-3l-2.3-5.7H1.7V7.8h4.7L8,12.8z'/%3E%3C/svg%3E%0A") !important;
      padding-right: 0.25em;
      padding-top: 0.125em;
    }
  }
}

#live-area ul.is-style-con-pro-list {
  li:nth-of-type(odd) {
    width: 100%;

    &:before {
      content: url('../images/FE_Prob_Roboto.svg');
      padding-right: 0.25em;
      padding-top: 0.125em;
      font-size: .9em;
    }
  }

  li:nth-of-type(even) {
    width: 100%;

    &:before {
      content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cpath class='st0' style='fill: %2370D44B;' d='M8,12.8l3.9-12.6h2.8L9.6,16h-3l-2.3-5.7H1.7V7.8h4.7L8,12.8z'/%3E%3C/svg%3E%0A") !important;
      padding-right: 0.25em;
      padding-top: 0.125em;
    }
  }
}

ul.is-style-con-pro-list {
  li:nth-of-type(odd) {
    &::marker {
      color: @brand-primary;
    }
  }

  li:nth-of-type(even) {
    &::marker {
      color: @brand-secondary;
    }
  }
}

#live-area {
  ul {
    @local-indent: @grid-gutter-width;

    &.is-style-inline-list-slash {
      width: 100%;
      display: inline-flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 0;

      li {
        width: auto !important;
        margin: 0 0 0 0 !important;
        padding: 0 0 0 0 !important;
        margin-bottom: .25em;
        font-size: .9em;
        color: @brand-tertiary !important;

        a {
          line-height: 1;
          color: @brand-tertiary !important;

          &:hover {
            color: @brand-text-on-dark !important;
            border-bottom: 2px solid @brand-text-on-dark !important;
          }
        }

        &:before {
          content: '' !important;
          width: 0 !important;
          min-width: 0 !important;
        }

        &:after {
          content: '|';
          margin-left: .5em;
          margin-right: .5em;
          color: @brand-tertiary;
        }

        &:last-child:after {
          content: '';
        }
      }
    }
  }
}

