
//  Default Menu Occlusion
//---------------------------------------------------------

@media (max-width: @mobile-menu-visible ) {
  #main-nav #navbar {
    display: none !important;
  }

  #main-nav {
    padding-top:(@grid-gutter-width/ 4);
    padding-bottom:(@grid-gutter-width/ 4);
    background: @main-nav-background;
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 301;
    > .container{
      width: 100%;
      max-width: 100%;
      //padding-left: (@grid-gutter-width /4);
      //padding-right: (@grid-gutter-width /4);
    }
  }
}
@media (min-width: @mobile-menu-visible ) {
  .mobile-only {
    display: none !important;
  }
}

#mobile-menu-container, #mobile-trigger {
  @media (min-width: @mobile-menu-visible ) {
    display: none !important;
  }
}

//  Trigger button styling | onClick Event Attached
//---------------------------------------------------------
@menu-bar-height: 2px;
#mobile-trigger {
  position: fixed;
  top: (@grid-gutter-width/ 4);
  right: (@grid-gutter-width / 4);
  font-family: @headings-font-family;
  overflow: hidden;
  white-space: nowrap;
  color: @main-nav-color;
  background:transparent;
  height: @logo-mobile-height;
  width: @logo-mobile-height;
  padding: 10px 5px;
  z-index: 301;
  transform: translateZ(0);
  // align button to bootstrap margin when container is not 100% wide

  &.open {
    // open class for animation of button context
  }
  #x-container {
    width:100%;
    position: relative;
    padding-bottom: 100%;
  }
}
@bar-container-height: (@logo-mobile-height - 20px);
#mobile-trigger span {
  display: block;
  position: absolute;
  height: @menu-bar-height;
  width: 100%;
  background: @main-nav-color;
  border-radius: 0px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#mobile-trigger span:nth-child(1) {
  top: ((@bar-container-height / 2 ) - (@menu-bar-height * 4));
}

#mobile-trigger span:nth-child(2), #mobile-trigger span:nth-child(3) {
  top: ((@bar-container-height / 2 ) - (@menu-bar-height * .5));
}

#mobile-trigger span:nth-child(4) {
  top: ((@bar-container-height / 2 ) + (@menu-bar-height * 3));
}

#mobile-trigger.open span:nth-child(1) {
  top: ((@bar-container-height / 2 ) - (@menu-bar-height * .5));
  width: 0%;
  left: 50%;
}

#mobile-trigger.open span:nth-child(2) {
  transform: rotate(45deg);
}

#mobile-trigger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#mobile-trigger.open span:nth-child(4) {
  top: ((@bar-container-height / 2 ) - (@menu-bar-height * .5));
  width: 0%;
  left: 50%;
}

//  Scroll Busting | scroll lock div is overlay
//---------------------------------------------------------

#scrollLock {
  display: block;
  position: fixed;
  width: 100%;
  height: 0;
  z-index: 200;
  top: 0;
  left: 0;
  background: @scrollLockColor;
  opacity: .0;
  visibility: hidden;
  transition: opacity @transition-time-global;
  &.scrollLock {
    opacity: 1;
    height: 100%;
    visibility: visible;
  }
}


body.scrollLock {
  overflow: hidden;
  min-height: 5000%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0px;
  #wrap {
    #page-header, #header-content, #page-container, div.row.bf-cta, footer, #copyright {
      //-webkit-filter: blur(5px);
      //-moz-filter: blur(5px);
      //-ms-filter: blur(5px);
      //-o-filter: blur(5px);
      //filter: blur(5px);
    }
  }
}

//  MENU OBJECT
//---------------------------------------------------------

.mobile-menu {
  font-family: @headings-font-family;
  display: block;
  width: 100%;
  max-height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 300;


  height: 0;
  transition: transform @transition-time-global, right @transition-time-global;

  font-size: 1.125em;
  background: @main-nav-background;
  transform: translateZ(0);
  &.open {
    top: (@logo-mobile-height + (@grid-gutter-width/ 2));
    border-top: 1px solid @brand-gray-light;
    height: 100%;
  }

  //  Nav Object
  //---------------------------------------------------------Fsub
  nav#mobile-menu-nav {
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    ul.menu {
      list-style: none;
      margin: 0px 0px 0px 0px;
      padding: 0px;
      > li {
        border-top: 1px solid @brand-gray;
      }
      li {
        position: relative;
        padding: 0 0 0 0;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;


        &.menu-item-has-children {
          position: relative;
          display: flex;
          align-items: stretch;
          flex-wrap: wrap;
          justify-content: space-between;
          > a {
            width: calc(100% - 5em);
          }
        }
        a {
          width: 100%;
          color: @main-nav-color;
          padding-top: @mobile-item-padding-vert;
          padding-left: @mobile-item-padding-horz;
          padding-right: @mobile-item-padding-horz;
          padding-bottom: @mobile-item-padding-vert;
          line-height: 1em;
          width: 100%;
          display: inline-flex;
          flex-wrap: wrap;
          align-content: center;
          align-items: center;
          justify-content: flex-start;
          font-weight: @font-weight-base;
          font-family: @font-family-base;
        }
        ul.sub-menu {
          list-style: none;
          position: relative;
          padding: 0 0 0 0;
          margin: 0 0 0 0;
          width: 100%;
          text-align: left;
          background: @main-nav-background;
          transform: translateZ(0);
          transition: transform @transition-time-global, max-height @transition-time-global;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 0px;
          li {
            border-top: 1px solid @brand-gray;
            border-left: 5px solid @brand-primary;
          }

        }
        li {
          padding-left: (@mobile-item-padding-horz * .25);
          //border-bottom: 1px solid @sub-menu-color;
        }
        li.sub-menu {
          ul.sub-menu {
            height: auto;
            max-height: 1000px;
          }


        }
        .submenulink, li.sub-menu .submenulink {


          color: @main-nav-color;
          background: @main-nav-background;
          width: auto;
          line-height: inherit;
          width: (@mobile-item-padding-vert * 3);
          padding: @mobile-item-padding-vert @mobile-item-padding-vert;
          text-align: center;
          cursor: pointer;
          position: relative;
          border: 0px solid transparent;
          border-left: 1px solid @brand-gray;
          i {
            //position: absolute;
            //left: 50%;
            //top: 50%;
            float: none !important;
            //margin-top: -0.5em;
            //margin-left: -0.4em;
          }
        }
        .submenulink.open + ul.sub-menu {
          max-height: 1000px;
        }

        //  First Submenu Leve
        //---------------------------------------------------------

      }
    }
  }
  // END NAV OBJECT

  //  Inner Trigger Objects
  //---------------------------------------------------------

  .submenulink {
    display: inline-block;
    color: @main-nav-color;
    background: @main-nav-background;
    width: 100%;
    padding: @mobile-item-padding-vert 0;
    box-sizing: border-box;
    i {
      margin-left: 0px;
      margin-right: 0px;
    }

  }

}

#mobile-menu-nav > ul.menu > li {
  position: relative;
}




@media (max-width: @mobile-menu-visible ) {

  body.logged-in {
    top: 46px;
    &.scrollLock {
      top: 0px !important;
      #wpadminbar {
        //display: none;
      }
    }
    #wp-admin-bar-new_draft,#wp-admin-bar-gform-forms {
      display: none !important;
    }

    #mobile-trigger, #main-nav {
      top: 46px;
    }
    #mobile-menu-container {
      top: (46px + @logo-mobile-height + (@grid-gutter-width/ 2));
    }
    #wpadminbar {
      position: fixed !important;
    }
  }
}

.store-launch {
  display: none !important;
}


// MOBILE MENU BASE_ANIMATION

#mobile-menu-container {
  transform: translateY(-120%);
  transform-origin: 100% 50%;
  &.open {
    transform: translateY(0);
  }
}
