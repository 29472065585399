

.bs-flex-fix{
  &:before,&:after{
    display: none !important;
  }
}
.justify-mixin{

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-space-evenly {
    justify-content: space-evenly;
  }
  &.justify-space-around {
    justify-content: space-around;
  }

}
.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.flex-container-mixin(@screen-min,@name) {
  @spec: ~"@{name}";
  // No Wrapping Solutions
  .direction-@{spec}-row {
    @media (min-width: @screen-min) {
      flex-direction: row;
    }
  }
  .direction-@{spec}-column {
    @media (min-width: @screen-min) {
      flex-direction: column;
    }
  }
  .wrap-@{spec}-no {
    @media (min-width: @screen-min) {
      flex-wrap: nowrap;
    }
  }
  .wrap-@{spec}-yes {
    @media (min-width: @screen-min) {
      flex-wrap: wrap;
    }
  }

  .justify-@{spec}-space-between {
    @media (min-width: @screen-min) {
      justify-content: space-between;
    }
  }
  .justify-@{spec}-space-around{
    @media (min-width: @screen-min) {
      justify-content: space-around;
    }
  }.justify-@{spec}-space-evenly{
     @media (min-width: @screen-min) {
       justify-content: space-evenly;
     }
   }
  .justify-@{spec}-end{
    @media (min-width: @screen-min) {
      justify-content: flex-end;
    }
  }
  .justify-@{spec}-start{
    @media (min-width: @screen-min) {
      justify-content: flex-start;
    }
  }
  .justify-@{spec}-center{
    @media (min-width: @screen-min) {
      justify-content: center;
    }
  }




  .flex-@{spec}-stretch {
    @media (min-width: @screen-min) {
      align-items: stretch;
      align-content: stretch;
    }
  }
  .flex-@{spec}-center {
    @media (min-width: @screen-min) {
      align-items: center;
      align-content: center;
    }
  }
  .flex-@{spec}-start {
    @media (min-width: @screen-min) {
      align-items: flex-start;
      align-content: flex-start;
    }
  }
  .flex-@{spec}-end {
    @media (min-width: @screen-min) {
      align-items: flex-end;
      align-content: flex-end;
    }
  }
  .flex-@{spec}-baseline {
    @media (min-width: @screen-min) {
      align-items: baseline;
      align-content: baseline;
    }
  }

}

.flex-container-mixin(0,~'xs');
.flex-container-mixin(@screen-sm-min,~'sm');
.flex-container-mixin(@screen-md-min,~'md');
.flex-container-mixin(@screen-lg-min,~'lg');

.flex-order-mixin(@screensize-min,@name,@size){
  @order: ~"@{size}";
  @scsize-name: ~"@{name}";
  @media ( min-width: @screensize-min) {
    .order-@{scsize-name}-@{order} {
      order: @size;
    }
  }
}


.flex-order-mixin(0,~'xs',~'1');
.flex-order-mixin(0,~'xs',~'2');
.flex-order-mixin(0,~'xs',~'3');
.flex-order-mixin(0,~'xs',~'4');

.flex-order-mixin(@screen-sm-min,~'sm',~'1');
.flex-order-mixin(@screen-sm-min,~'sm',~'2');
.flex-order-mixin(@screen-sm-min,~'sm',~'3');
.flex-order-mixin(@screen-sm-min,~'sm',~'4');

.flex-order-mixin(@screen-md-min,~'md',~'1');
.flex-order-mixin(@screen-md-min,~'md',~'2');
.flex-order-mixin(@screen-md-min,~'md',~'3');
.flex-order-mixin(@screen-md-min,~'md',~'4');

.flex-order-mixin(@screen-lg-min,~'lg',~'1');
.flex-order-mixin(@screen-lg-min,~'lg',~'2');
.flex-order-mixin(@screen-lg-min,~'lg',~'3');
.flex-order-mixin(@screen-lg-min,~'lg',~'4');
