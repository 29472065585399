
#live-area {
  z-index: 1;
  position: relative;
  border-color: @brand-primary;
  display: block;
  float: left;
  width: 100%;
  @media (max-width: @screen-sm-max) {
    overflow: hidden;
  }
  img {
    max-width: 100%;
  }
  section.is-bg-primary a:not(.btn) {
    color: @brand-text-on-dark;
    text-decoration: underline;
  }
}

#content {
}
section:first-child {
  padding-top: @logo-height;
  @media (max-width: @screen-md-max) {
    padding-top: (@logo-adjusted-height);
  }
  @media (max-width: @screen-sm-max) {
    padding-top: (@logo-mobile-height + (@grid-gutter-width / 2));
  }
}

section {
  padding: 0 0;
  display: block;
  float: left;
  position: relative;
  width: 100%;
  z-index: 1;
  background-repeat: no-repeat !important;
  //background-size: cover !important;
  //background-position: center center !important;
  .container-fluid, .container {
    padding-top: (@grid-gutter-width * @default-section-padding-factor);
    padding-bottom: (@grid-gutter-width * @default-section-padding-factor);
  }

  .row {

  }

}

.column {
  padding-top: (@grid-gutter-width * @default-column-padding-factor);
  padding-bottom: (@grid-gutter-width * @default-column-padding-factor);
  order: 1;

  &:empty {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (max-width: @screen-xs-max) {
    //padding-left:          floor(((@base-em-size * @line-height-base-value) * @column-horz-phone-padding));
    //padding-right:          floor(((@base-em-size * @line-height-base-value) * @column-horz-phone-padding));

  }
}

article {
  display: block;
  position: relative;
}

div.timed-wrapper {
  width: 100%;
  max-width: none;
  overflow: hidden;
}


@import "archive-feed-blog.less";
