

#blog-feed {
  padding-top: 0px;


  .featured-article {
    background: @brand-gray-dark;
    @media(min-width: @screen-md-min) {
      .article-content-container {
        order: 1;
      }

      .article-image {
        order: 2;
      }
    }
    @media(max-width: @screen-sm-max){
      .article-content-container {
        order: 2;
      }
      .article-image {
        order: 1;
      }
    }

    .article-details-lead {
      width: 100%;
      order: 0;
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      padding-bottom: (@grid-gutter-width/2);
      div.date {
        text-align: left;
        width: 75%;
        width: calc(100% - ( @grid-gutter-width * 2));
        font-size: 16px;
        color: @brand-gray;
      }
      div.type {

        text-align: right;
        width: (@grid-gutter-width * 2);
        height: @grid-gutter-width;
        padding-left: (@grid-gutter-width);
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .readmore-link {
    text-decoration: none;
    color: @brand-black;
  }
  div.row.articles-feed{
    .articles-container {
      display: flex;
      .bs-flex-fix();
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: center;

      .feed-article{
        .content-container {
          padding: (@grid-gutter-width);
        }
      }
      .feed-article {

        height: auto;
        background: #fff;
        display: inline-block;
        .featured-image-article {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 50%;
          overflow: hidden;
          img {
            position: absolute;
            top:0;
          }

        }
        .content-container {
          display: inline-flex;
          flex-wrap: wrap;
          width: 100%;
          .article-details-lead {
            width: 100%;
            order: 0;
            display: inline-flex;
            align-items: center;
            flex-wrap: nowrap;
            padding-bottom: (@grid-gutter-width/2);
            div.date {
              text-align: left;
              width: 75%;
              width: calc(100% - ( @grid-gutter-width * 2));
              font-size: 16px;
              color: @brand-gray;
            }
            div.type {

              text-align: right;
              width: (@grid-gutter-width * 2);
              height: @grid-gutter-width;
              padding-left: (@grid-gutter-width);
              img {
                width: 100%;
                height: auto;
              }
            }
          }


          .feed-header {
            width: 100%;
            order: 1;
            a {
              color: inherit;
            }
            h2 {
              margin-bottom: (@grid-gutter-width/4);
            }

          }
          div.excerpt {
            width: 100%;
            order: 2;
            font-size: 14px;
            line-height: @line-height-base;
            padding-bottom: (@grid-gutter-width/4);
            p:last-child{
              margin-bottom: (@grid-gutter-width/4);
            }
            border-bottom: 2px solid @brand-primary;
            margin-bottom: (@grid-gutter-width/4);
          }
          div.vendor {
            display: inline-block;

            padding-top: (@grid-gutter-width/4);
            width: 80%;
            order: 4;
            padding-bottom: 10%;
            position: relative;
            img.img-contain {
              position: absolute;
              object-position: left center;
            }
          }
        }
      }
      .feed-article{
        width: 30%;
        width:calc(33% - (@grid-gutter-width/2));
        margin-left: (@grid-gutter-width/4);
        margin-right: (@grid-gutter-width/4);
        margin-bottom: (@grid-gutter-width/2);
        @media(max-width: @screen-md-min ){
          width: 50%;
          width:calc(50% - (@grid-gutter-width/2));
        }
        @media(max-width:@screen-sm-min){
          width: 100%;
          width:calc(100% - (@grid-gutter-width/2));

        }

      }
    }
  }
}
#blog-article {
  .container-fluid {
    .row{
      justify-content: center;
      .container-article {
        padding: (@grid-gutter-width/2);
        background: #fff;
        width: 700px;
        max-width: 100%;
        .featured-image-article {
          margin-bottom: (@grid-gutter-width/2);
        }
        .article-details-lead {
          width: 100%;
          order: 0;
          display: inline-flex;
          align-items: center;
          flex-wrap: nowrap;
          padding-bottom: (@grid-gutter-width/2);
          div.date {
            text-align: left;
            width: 75%;
            width: calc(100% - ( @grid-gutter-width * 2));
            font-size: 16px;
            color: @brand-gray;
          }
          div.type {

            text-align: right;
            width: (@grid-gutter-width * 2);
            height: @grid-gutter-width;
            padding-left: (@grid-gutter-width);
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

section.full-header-inside {
  padding-top: 4em;

}


.full-header-inside.bg-black {
  h1, span, a {
    color: #fff;
  }
}

  .bg-primary{ background-color: @brand-primary; }
  .bg-secondary{ background-color: @brand-secondary; }
  .bg-tertiary { background-color: @brand-tertiary; }
  .bg-black{ background-color: @brand-black; }
  .bg-gray-dark { background-color: @brand-gray-dark; }
  .bg-gray { background-color: @brand-gray; }
  .bg-gray-light { background-color: @brand-gray-light; }
  .bg-text-on-dark{ background-color: @brand-text-on-dark; }
  .bg-white { background-color: @brand-text-on-dark; }



#live-area h2.no-margin-bottom {
  margin-bottom: .5em;
}


#live-area .menu-text-box ul {
  width: auto;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
}

@media (max-width: 768px) {
  #live-area .menu-text-box ul {
    justify-content: center;
  }

}

#live-area .menu-text-box ul li {
  width: auto;
  display: inline-block;
  margin-bottom: 0px;
  padding: 0em;
  padding-right: 1em;
  margin-left: 0em;
  padding-left: 1em;
  border-left: 1px solid;
  color: #ffffff !important;
}

#live-area .menu-text-box ul li a {
  color: #ffffff !important;
}



#live-area .menu-text-box ul li:before {
  display: none;
}


#live-area .menu-text {
  font-size: 1.5rem !important;
  line-height: 1.5em !important;
}


@media (max-width: 768px) {
  #live-area .in-page-nav .nav-header {
    display: block;
    text-align: left;
    padding-bottom: 1em;
  }
}

@media (max-width: 768px) {
  #live-area .in-page-nav .nav-icon {
    display: block;
    text-align: left;
  }
}


div.poster-info {
  display: flex;
  align-items: stretch;
  margin-top: 1em;
  flex-wrap: wrap;

}

div.poster-info:before {
  content: '';
  height: 3px;
  background: linear-gradient(90deg, var(--brand-primary) 0%, var(--brand-secondary) 50%, var(--brand-tertiary) 100%);
  margin-bottom: 1em;
  width: 100%;
  display: inline-block;
}

div.poster-info div.image {
  width: 96px;
  height: 96px;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 1em;

}

div.poster-info div.image img {
  object-fit: cover;
  object-position: center center;
  max-width: none;
  width: 100%;
  height: 100%;
}

div.poster-info div.author {
  width: calc(100% - 96px - 1em);
  box-sizing: border-box;
  padding: .5em 1em;
  border-left: 1px solid var(--brand-gray-light);
}

div.poster-info div.author span.posted-by {
  font-size: .675em;
  font-style: italic;
  color: var(--brand-gray-light);
  width: 100%;
  display: inline-block;
}

div.poster-info div.author span.author-name {
  width: 100%;
  font-size: 1.125em;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  line-height: 1em;
  margin-bottom: 1em;
  display: inline-block;
  font-weight: 100;
}


div.poster-info div.author p.author-description {
  width: 100%;
  margin-bottom: 0;
  font-size: .875em;
  color: var(--brand-gray);
}

#blog-article .container-fluid .row .container-article .featured-image-article {
  display: inline-block;
  width: calc(100% + 5em);
  margin-left: -2.5em;
  margin-top: -1.5em;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, .25)
}


//Careers
.img-icon-md, .icon-center {
  display: inline-block;
}

.icon-center {
  width: auto;
}
.hr-primary {
  content: '';
  height: 3px;
  background: linear-gradient(90deg, var(--brand-primary) 0%, var(--brand-secondary) 50%, var(--brand-tertiary) 100%);
  width: 100%;
  display: inline-block;
}

.fa-briefcase::before {
  color: var(--brand-secondary);
}

.fa-briefcase.closed::before {
  color: var(--brand-primary);
}
.btn-career {
  color: var(--brand-primary);
  background-color: var(--brand-text-on-dark);
  border-color: var(--brand-primary);
  &:hover {
    color: var(--brand-text-on-dark);
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }
}



#live-area h2.separator-object:after {
  content: '';
  padding-top: .25em;
  border-bottom: 0.25em solid #ff4d00;
  width: 200px;
  display: block;
  clear: left;
  border-radius: 0.125em;
}

//Single Post Next - Previous

.bst-posts-nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  a {
    display: grid;
    grid-gap: 20px;
    align-items: center;
    svg {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }
  }
  h4,
  strong {
    margin: 0;
  }
}







.bst-posts-nav > div:nth-child(1) a {
  grid-template-columns: 50px 1fr;
  text-align: left;
}

.bst-posts-nav > div:nth-child(2) a {
  grid-template-columns: 1fr 50px;
  text-align: right;
}


@media (min-width: 700px) {


  .bst-posts-nav > div:nth-child(1) a {
    grid-template-columns: 100px 1fr;
    text-align: left;
  }

  .bst-posts-nav > div:nth-child(2) a {
    grid-template-columns: 1fr 100px;
    text-align: right;
  }
}

@media (max-width: 700px) {
  .bst-posts-nav {
    display: block;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
  }

  .bst-posts-nav > div:nth-child(1) a {

    text-align: left;
    padding: 1em 0
  }

  .bst-posts-nav > div:nth-child(2) a {
    text-align: right;
  }
}


.bst-posts-nav__thumbnail {
  display: block;
  margin: 0;
  img {
    border-radius: 10px;
  }
}

.page-search-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

#live-area .search-content {

  width: 100%;
  padding: 1em 0;

 h2 {
   margin-bottom: .25em;
 }
}

#searchform {
  color: inherit;
  display: flex;
  align-items: center;
  input {
    max-width: 100%;
    width: calc(100% - 3em);
    padding: 0.25em 0.5em 0.25em 1em;
    background: transparent;
    color: #fff;
    border: 1px solid @brand-gray-light;
    border-radius: 3em 0 0 3em;


  }

  button {
    width: 3em;
    text-align: center;
    padding: 0 0 0 0;
    padding-top: .75em;
    padding-bottom: .75em;
    border-radius: 0 3em 3em 0;
    border: 1px solid @brand-primary !important;
  }
}

#live-area #article-container ul li:before {
  color: @brand-tertiary;
}


#live-area {
  .feg-query-block {

    :where(.wp-block-columns) {
      margin-bottom: 0;
    }

    .wp-container-18.wp-container-18 {
      grid-template-columns: repeat(4, minmax(0, 1fr));

      @media (max-width: @screen-md-max) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @media (max-width: @screen-sm-max) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }

    .wp-block-post-date {
      box-sizing: border-box;
      padding: 0 1rem;
      font-size: .75rem;
    }

    li {
      width: 100%;
      padding-left: 0;
      padding-bottom: 9px;
      margin-bottom: 9px !important;
      background-color: @brand-text-on-dark;
    }

    li:before {
      content: '';
    }

    picture {
      aspect-ratio: 5/3 !important;
      margin-bottom: .5em !important;

      img {
        object-position: center;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    h2 {
      font-size: 1.125em;
      padding: 0 1rem;
      margin-bottom: .5rem;
    }

    p {
      font-size: .875em;
      padding: 0 1em 1em 1em;
      line-height: 1.33em;
    }
  }
}
