// GRAVITY FORMS
@form-border-radius: 1em;
@form-border-width: 1px;
@form-border-style: solid;
@form-padding-horz: 1em;
@form-padding-vert: .25em;
#live-area .gform_wrapper.gravity-theme .gform_fields {
  grid-column-gap: (@grid-gutter-width/2);
  grid-row-gap: (@grid-gutter-width/2);
}

.container, .container-fluid {

  .gform_wrapper.gravity-theme {
    .gform_required_legend {
      display: none;
    }

    label, legend {
      width: 100%;
      font-family: @headings-font-family;
      font-weight: @headings-font-weight;
      font-size: 1em;
      color: #00556e;
    }

    .gchoice, .ginput_container_consent, .ginput_complex {
      label, legend {
        font-weight: @font-weight-base;
        font-family: @font-family-base;
        font-size: @font-size-base;
      }
    }

    .ginput_complex {
      label, legend {
        font-size: (@font-size-base * .75);
      }
    }

    input[type=color],
    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    textarea {
      border: @form-border-width @form-border-style;
      border-radius: @form-border-radius;
      background-color: @brand-gray-light;
      padding: @form-padding-vert @form-padding-horz @form-padding-vert @form-padding-horz;
      font-size: @font-size-base;
      line-height: @line-height-base;
      font-weight: @font-weight-base;

      &:focus {
        outline: 0;
        box-shadow: 0px 0px 0px 2px @brand-primary;
      }
    }
  }

  .gform_button.button {
    .btn();
    .btn-default();
  }
}

#gform_submit_button_1.gform_button.button {
  .btn();
  font-size: 1.25em;
  border: 2px solid #00556e;
  background-color: #00556e;
  color: @brand-text-on-dark;
  transition: .3s ease;

  &:hover {
    border: 2px solid #00556e;
    background-color: @brand-text-on-dark;
    color: #00556e;
    transition: .3s ease;
  }
}
