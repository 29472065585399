@header-logo: url("../images/@{logo-positive-name}.svg");
@header-logo-fallback: url('../images/@{logo-positive-name}3x.png');
@header-logo-color-fallback: rgba(0,0,0,0);
@logo-width:( 178px * 2);
@logo-height: (40px * 2);
@logo-adjusted-height: (@logo-height * .75);
@logo-adjusted-width: (@logo-width * .75);
@logo-mobile-height: (@logo-height * .75);
@logo-mobile-width: (@logo-width * .75);

@main-nav-family : @headings-font-family;
@main-nav-fsize: 1em;
@main-nav-fsize-small: .8em;
@main-nav-fstyle: inherit;
@main-nav-ftransform: normal;
@main-nav-fweight: 700;
@main-nav-height: @logo-height;


@main-nav-adjusted-height: @logo-adjusted-height;
@main-nav-background : linear-gradient(to top, rgba(0, 34, 63, 1), rgba(0, 34, 63, 0.8));
@main-nav-color: #fff;
@main-nav-color-hover: lighten(#00223f,10%);
@main-nav-top-level-pad: .75em;
@main-nav-top-level-padding: @main-nav-top-level-pad @main-nav-top-level-pad @main-nav-top-level-pad @main-nav-top-level-pad;
@perpval : 1000px; // determines perspecitve of transform

@sub-menu-family: @headings-font-family;
@sub-menu-top: @main-nav-height;
@sub-menu-left: (@sub-menu-width * -.5);
@sub-menu-adjusted-top: @main-nav-adjusted-height;
@sub-menu-padding : 0em 0em;
@sub-menu-fsize: .75em;
@sub-menu-fweight: 700;
@sub-menu-width: 250px;
@sub-menu-width-adjusted: 225px;
@sub-menu-left-adjusted: (@sub-menu-width-adjusted * -.45);

@sub-menu-transition-max-height : 300px;
@sub-menu-bg: @main-nav-background;
@sub-menu-bg-hover: @main-nav-color-hover;
@sub-menu-color: #fff;
@sub-menu-color-hover: @brand-text-on-dark;
@sub-menu-link-padding-vert: 1.5em;
@sub-menu-link-padding-horz: 2em;
@sub-sub-menu-link-padding-vert: @sub-menu-link-padding-vert / 2;
@sub-sub-menu-link-padding-horz: 2em;
@sub-menu-seperator-color: @main-nav-color-hover;

//---------------------------------------------------------
//        Mobile Menu Variables
//---------------------------------------------------------
@main-nav-height-mobile: 100px;
@mobile-item-padding-horz: (@grid-gutter-width / 2);
@mobile-item-padding-vert: 1em;
@scrollLockColor: @brand-black;

@mobile-menu-visible : @screen-sm-max;


.navbar {
  z-index: 1;
  display: block;
  min-height: 0px;

}

.nav {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;

  li {
    z-index: 1;
    display: inline-block;
    width: auto;
    position: relative;

    a {
      display: inline-block;
      width: 100%;
    }

    ul {
      z-index: 2;
      position: absolute;
      top: 0px;
      left: 0px;
      display: none;

      li {
        width: 100%;

        a {
          width: 100%;
        }

        ul {
          position: relative;
          width: 100%;
          padding-left: 0em;

          li {
            width: 100%;
            padding-left: 0px;

            a {
              width: 100%;
            }
          }

        }
      }
    }

    a:hover, a:focus, a:active {
      + ul {
        display: block;
      }
    }

    &:hover, &:focus, &:active {
      ul {
        display: block;
      }
    }

  }
}

#main-nav {
  font-family: @headings-font-family;
  text-transform: normal;
  border: 0px solid;
  position: relative;
  z-index: 6;

  > div.container {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
  }
}




#main-nav {
  position:absolute;
  width: 100%;
  z-index: 9;
  background: @main-nav-background;
  font-size: @main-nav-fsize;
  font-weight: @main-nav-fweight;
  font-style: @main-nav-fstyle;
  line-height: .9;
  border-bottom: 1px solid @main-nav-color-hover;
  padding: 0 0;
  //position: relative;
  @media (max-width: @screen-md-max) {
    font-size: @main-nav-fsize-small;
  }

  .brand-container {
    display: inline-block;
    vertical-align: middle;

    a.navbar-brand {
      display: inline-block;
      vertical-align: middle;
      padding: 0px;
      margin: 0px;

      #logo {
        height: @logo-height;
        //width: @logo-width;

        position: relative;
        width: auto;

        img {
          padding-top: (@grid-gutter-width * .25);
          padding-bottom: (@grid-gutter-width * .25);
          width: auto;
          height: 100%;
        }

        @media (max-width: @screen-md-max) {
          height: @logo-adjusted-height;
          //width: @logo-adjusted-width
        }
        @media (max-width: @mobile-menu-visible) {
          height: @logo-mobile-height;
          //width: @logo-mobile-width
        }
      }
    }
  }

  .navbar-collapse {
    position: relative;
    display: inline-block;

    > div {
      display: inline-block;

      > ul {
        display: inline-flex;
        position: relative;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;

        li {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;

        }

        li a {
          display: inline-block;
          width: 100%;

        }

        > li {
          font-family: @main-nav-family;
          font-weight: @main-nav-fweight;
          font-style: @main-nav-fstyle;
          text-transform: @main-nav-ftransform;
          line-height: @main-nav-height;
          height: @main-nav-height;
          @media (max-width: @screen-md-max) {
            line-height: @logo-adjusted-height;
            height: @logo-adjusted-height;
            //width: @logo-adjusted-width
          }
          @media (max-width: @mobile-menu-visible) {
            line-height: @logo-mobile-height;
            height: @logo-mobile-height;
            //width: @logo-mobile-width
          }
          border-left: 1px solid @main-nav-color-hover;
          padding-left: .5em;
          padding-right: .5em;
          &:last-child {
            border-right: 1px solid @main-nav-color-hover;
          }
          &:hover, &:focus, &:active {
            background: @main-nav-color-hover;
          }
          @media (max-width: @screen-md-max) {
            line-height: @main-nav-adjusted-height;
          }

          > a {
            line-height: 1;
            display: inline-block;
            vertical-align: middle;
            float: none;
            padding: @main-nav-top-level-padding;
            color: @main-nav-color;

            &:hover, &:focus, &:active {
              color:#fff;

              &:before {
                //color: @main-nav-color-hover;
              }
            }
          }

          > ul {
            line-height: 1.25em;
            //border-top: 5px solid @sub-menu-seperator-color;
            padding: @sub-menu-padding;
            background: @sub-menu-bg;
            font-size: @sub-menu-fsize;
            display: block;
            visibility: hidden;
            overflow: hidden;
            transform-origin: 50% 0;
            transform: rotateX(-90deg);
            transition: transform .25s ease-in-out;
            left: 50%;
            margin-left: @sub-menu-left;
            border: 1px solid @main-nav-color-hover;

            top: (@sub-menu-top);
            width: @sub-menu-width;
            @media (max-width: @screen-md-max) {
              top: @sub-menu-adjusted-top;
              width: @sub-menu-width-adjusted;
              margin-left: @sub-menu-left-adjusted;
            }

            li {
              vertical-align: top;
              overflow: hidden;
              font-family: @sub-menu-family;
              font-weight: @sub-menu-fweight;

              a {
                border-top: 1px solid @sub-menu-seperator-color;
              }


              a {

                overflow: visible;
                padding: @sub-menu-link-padding-vert @sub-menu-link-padding-horz;
                line-height: 1em;
                color: @sub-menu-color;

                &:hover, &:focus, &:active {
                  background: @sub-menu-bg-hover;
                  color: @sub-menu-color-hover;
                }

                &:before {
                  transition: margin-left .5s;
                }

              }

              &.menu-item-has-children {
                a {
                  padding-bottom: @sub-sub-menu-link-padding-vert;
                }

                padding-bottom: @sub-sub-menu-link-padding-vert;
              }

              li {
                border-top: 0px solid @sub-menu-seperator-color;
                padding: 0 0 0 0;

                &:last-child {
                  //padding-bottom: @sub-menu-link-padding-vert;
                  a:last-child {
                    //border-bottom: 1px solid @sub-menu-seperator-color;
                    border-bottom: 0;
                  }
                }

                a {
                  text-indent: 1em;
                  border-top: 1px solid @sub-menu-seperator-color;
                }

                a {
                  padding: @sub-sub-menu-link-padding-vert @sub-sub-menu-link-padding-horz;
                }
              }

            }
          }
          &.wpml-ls-item > ul {
            text-align: center;
            width: 100%;
            margin-left: -50%;
            @media (max-width: @screen-md-max) {
              width: 100%;
              margin-left: -50%;
            }
          }

        }

        > li:hover ul, > li a:hover ~ ul, > li a:focus ~ ul,  > li:focus ul, > li a:focus + ul > li:active ul, > li a:active + ul, li .submenulink.open + ul {
          visibility: visible;
          transform: rotateX(0deg);
        }
        li:focus > .submenulink,li:hover > .submenulink {
          i {
            transform: rotateX(180deg);
          }
          &.open i{
            transform: rotateX(180deg) translateY(-.125em);
          }
        }
      }
      .submenulink {
        background: transparent;
        color: inherit;
        border: 0;
        margin-left: -.125em;
        font-size: .875em;
        &.open i {
          color: @main-nav-color-hover;
        }
      }
    }
  }
}

.submenulink {
  i {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    transform: rotateX(0) translateY(0);
    transition: transform @transition-time-global;
    position: relative;
    display:inline-block;
    span {
      background: @main-nav-color;
      height: .125em;
      width: calc(50% + .125em);
      position:absolute;
      display: block;
      top: 25%;
      transition: transform @transition-time-global;

      &.dd-l {
        left: .125em;
        transform-origin: 0 0;
        transform: rotateZ(45deg) scaleX(1);
      }
      &.dd-r {
        right: .125em;
        transform-origin: 100% 0;
        transform: rotateZ(-45deg) scaleX(1);
      }
    }
  }
  &.open i {
    color: @main-nav-color-hover;
    transform: rotateX(180deg) translateY(-.125em);
    //transform: rotateX(180deg);
    span {
      &.dd-l {
        transform: rotateZ(45deg) scaleX(1.875);
      }
      &.dd-r {
        transform: rotateZ(-45deg) scaleX(1.875);
      }
    }
  }
}
span.nav_desc {
  font-size: .875em;
  width: 100%;
  display: inline-block;
  line-height: @line-height-base;
  color: inherit;
  font-weight: @font-weight-base;
  font-family: @font-family-base;
  margin-top: .5em;

}
span.menu-text {
  display: inline-block;
  width: 100%;
}


div.content-navigation-links {
  width: 100%;
  position:absolute;
  top: 0;
  left: 0;
  height: auto;
  text-align: center;
  background: #fff;
  transform: translateY(-100px);
  z-index: 99999;
  &:focus-within {
    transform: translateY(0);
  }
}

.wpml-ls-item .submenulink {
  display:none;
}
