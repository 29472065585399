body {

  --wp--width--max : 100%;
  --wp--width--wide : 100%;
  --wp--width--full : 100%;
  @media(min-width:@screen-sm-min) {
    --wp--width--max: @container-sm-reduced;
    --wp--width--wide: @container-sm;
  }
  @media(min-width:@screen-md-min) {
    --wp--width--max: @container-md-reduced;
    --wp--width--wide: @container-md;
  }
  @media(min-width:@screen-lg-min) {
    --wp--width--max: @container-lg-reduced;
    --wp--width--wide: @container-lg;
  }
  @media(min-width:@screen-xl-min) {
    --wp--width--max: @container-xl-reduced;
    --wp--width--wide: @container-xl;
  }

}
#live-area {
  #content  {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    flex-direction: column;
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > p,
    > ul,
    > ol,
    > blockquote,
    > a,
    > table,
    > [class*='wp-block-']{

      float:left;
      clear: both;
      //max-width: 100%;
      width: var(--wp--width--max);
      margin-left: auto;
      margin-right: auto;
      //margin-left: calc(50% - var(--wp--width--max) * .5);
      //margin-right: calc(50% - var(--wp--width--max) * .5);
      box-sizing: border-box;
      padding-left: (@grid-gutter-width /2 );
      padding-right: (@grid-gutter-width /2 );
      //border-left: 1px solid red;
      &.alignwide {
        width: var(--wp--width--wide);
        //margin-left: calc(50% - var(--wp--width--wide) * .5);
        //margin-right: calc(50% - var(--wp--width--wide) * .5);
      }
      &.alignfull {
        width: var(--wp--width--full);
        max-width: 100%;
        //margin-left: 0;
        //margin-right: 0;
      }
      &.align-inline {
        width: auto;
        margin-left: 0;
        margin-right:(@paragraph-margin-factor * @grid-gutter-width);
      }
    }
  }
  .wp-block-group {
    width: 100%;
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);
    .wp-block-group__inner-container {
      width: 100%;
      display: inline-block;
      margin: 0 0 0 0;
      > * {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }

    > h1,h2,h3,h4,h5,h6 {
      width: auto;
    }
  }
}

//Gutenberg patch for 17.2
body {
  .wp-block-group-is-layout-constrained {
    //display: inline-block;
    width :100%;
    > :not(.alignleft):not(.alignright):not(.alignfull) {
      max-width: 100%;
      //margin: 0 0 0 0;
    }
  }
}
