#live-area {
  img, picture, picture img {
  max-width :100%;
  object-fit: contain;
  display: inline-block;
  height: auto;
//width: 100%;
//height: 100%;
}

}
img, picture, picture img {
&.alignright {
float:right;
margin-left:1em;
display:inline-block;
}
&.alignleft {
float:left;
margin-right:1em;
display:inline-block;
}
&.aligncenter {
display: block;
margin-right: auto;
margin-left: auto;
}
&.text-center { display: inline-block; margin:auto auto; }
}

@media(max-width:@screen-md-min) {
img.alignleft,img.alignright {
&.size-full {
  float:none;
  display:block;
}
}
img, picture, picture img {
&.alignright ,
&.alignleft {
  float:none;
  display:block;
}
}
}

@media(max-width:@screen-sm-min) {
img, picture, picture img {
&.alignright,
&.alignleft {
  display: block;
  float: none;
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: 100%;
  height: auto;
}
}
}
