@header-height-factor : .5;
div.heading-wrap {

  box-sizing: border-box;
  padding-top : (@grid-gutter-width * @header-height-factor);
  padding-bottom : (@grid-gutter-width * @header-height-factor);
  width: 100%;
  //height: (@grid-gutter-width * @header-height-factor);
  display: flex;
  align-items: flex-end;

  background-size: cover;
  background-position:center center;
  //box-shadow: inset 0px -85px 30px -24px #ffffff;

  overflow: hidden;
  position:relative;
}
#term-description {
  p:last-child {
    margin-bottom: 0px;
  }
}


.heading-wrap-black {
  background: @brand-black;
  color: @brand-text-on-dark;
  padding: 7em 0 3em 0;
}
