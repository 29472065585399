
#sidebar {
  padding-bottom: 1em;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  aside {
    border-left: 1px solid @sidebar-border-color;
    width: 100%;
  }

  ul li {
    padding-left: 0;

    &:before {
      display: none;
    }

    li {
      text-indent: 1em;

      li {
        text-indent: 2em;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .widget_nav_menu {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  h4 {
    padding: .25em .5em;
    border-bottom: 1px solid @sidebar-border-color;
    margin-bottom: 0px;
  }

  ul {
    list-style: none;
    //text-transform: uppercase;
    font-family: @headings-font-family;
    padding-left: 0px;
    margin-left: 0px;
    margin-bottom: 0px;

    li {
      a {
        text-decoration: none;
        color: @text-color;
        font-size: .75em;
        padding: .25em 1em;
        width: 100%;
        display: block;
        border-bottom: 1px solid @sidebar-border-color;

        &:hover {
          color: @brand-primary;
        }
      }
    }
  }


}


/* 3RD TIER PAGE TEMPLATE */

.row.page-sidebar-row {
  display: flex;
  align-items: stretch;
  @media (max-width: @screen-md-min) {
    flex-wrap: wrap;
  }
}


.search-content {
  padding: 1em 0;
}


