////---- BICON FONT ---------------------------------------------------------
////-------------------------------------------------------------------------
////-------------------------------------------------------------------------

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,700&display=swap');

@font-face{
  font-family:"ICO";
  src:url("../fonts/icomoon.eot?#iefix");
  src:url("../fonts/icomoon.eot?#iefix") format("eot"),url("../fonts/icomoon.woff") format("woff"),url("../fonts/icomoon.ttf") format("truetype"),url("../fonts/icomoon.svg") format("svg");
}


////-------------------------------------------------------------------------
////-------------------------------------------------------------------------


//@font-face{
//  //font-family:"Helvetica Neue LT W01_55 Roman";
//
//  src:url("../fonts/SpecialtyFonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix");
//  src:url("../fonts/SpecialtyFonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"),url("../fonts/SpecialtyFonts/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"),url("../fonts/SpecialtyFonts/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff"),url("../fonts/SpecialtyFonts/8a8bfee3-197c-4942-9b11-71508cc9f406.ttf") format("truetype"),url("../fonts/SpecialtyFonts/5101995a-e73b-4cf9-84e8-f99eb43277b1.svg#5101995a-e73b-4cf9-84e8-f99eb43277b1") format("svg");
//  font-family:"Helvetica Body";
//  font-style: normal;
//  font-weight: 400;
//}
