

 //--- Screensize padding mixin


.pad-mixin-screen-size-thing(@screensize,@screensize-min,@size,@padding-factor) {
  @padding-size: ~"@{size}";
  @scsize-name: ~"@{screensize}";
  @media ( min-width: @screensize-min) {
    .pad-@{scsize-name}-@{padding-size}, div.pad-@{scsize-name}-@{padding-size}, .container.pad-@{scsize-name}-@{padding-size} {
      padding: (@padding-factor * @grid-gutter-width );
      > .row {
        margin-left: (@grid-gutter-width * @padding-factor * -1);
        margin-right: (@grid-gutter-width * @padding-factor * -1);
      }

      &.column > .row {
        margin-left: (@grid-gutter-width * @padding-factor * -1);
        margin-right: (@grid-gutter-width * @padding-factor * -1);
        width :calc( 100% + (@grid-gutter-width * @padding-factor * 2));
      }
    }

    .pad-@{scsize-name}-horz-@{padding-size}, div.pad-@{scsize-name}-horz-@{padding-size}, .container.pad-@{scsize-name}-horz-@{padding-size} {
      padding-left: (@padding-factor * @grid-gutter-width );
      padding-right: (@padding-factor * @grid-gutter-width );
      > .row {
        margin-left: (@grid-gutter-width * @padding-factor * -1);
        margin-right: (@grid-gutter-width * @padding-factor * -1);
      }
      &.column > .row {
        margin-left: (@grid-gutter-width * @padding-factor * -1);
        margin-right: (@grid-gutter-width * @padding-factor * -1);
        width :calc( 100% + (@grid-gutter-width * @padding-factor * 2));
      }
    }

    .pad-@{scsize-name}-vert-@{padding-size}, div.pad-@{scsize-name}-vert-@{padding-size}, .row.pad-@{scsize-name}-vert-@{padding-size}, .container.pad-@{scsize-name}-vert-@{padding-size} {
      padding-top: (@padding-factor * @grid-gutter-width );
      padding-bottom: (@padding-factor * @grid-gutter-width );
    }

    .pad-@{scsize-name}-top-@{padding-size}, div.pad-@{scsize-name}-top-@{padding-size}, .row.pad-@{scsize-name}-top-@{padding-size}, .container.pad-@{scsize-name}-top-@{padding-size} {
      padding-top: (@padding-factor * @grid-gutter-width );
    }

    .pad-@{scsize-name}-bottom-@{padding-size}, div.pad-@{scsize-name}-bottom-@{padding-size}, .row.pad-@{scsize-name}-bottom-@{padding-size}, .container.pad-@{scsize-name}-bottom-@{padding-size} {
      padding-bottom: (@padding-factor * @grid-gutter-width );
    }

    .pad-@{scsize-name}-left-@{padding-size}, div.pad-@{scsize-name}-left-@{padding-size}, .container.pad-@{scsize-name}-left-@{padding-size} {
      padding-left: (@padding-factor * @grid-gutter-width );
    }

    .pad-@{scsize-name}-right-@{padding-size}, div.pad-@{scsize-name}-right-@{padding-size}, .container.pad-@{scsize-name}-right-@{padding-size} {
      padding-right: (@padding-factor * @grid-gutter-width );
    }

    section.pad-@{scsize-name}-@{padding-size}, .row.pad-@{scsize-name}-@{padding-size},
    section.pad-@{scsize-name}-horz-@{padding-size}, .row.pad-@{scsize-name}-horz-@{padding-size},
    section.pad-@{scsize-name}-left-@{padding-size}, .row.pad-@{scsize-name}-left-@{padding-size},
    section.pad-@{scsize-name}-right-@{padding-size}, .row.pad-@{scsize-name}-right-@{padding-size}{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@pad-factor-0: .0;
@pad-factor-1: .5;
@pad-factor-2: 1;
@pad-factor-3: 1.5;
@pad-factor-4: 2;
@pad-factor-5: 2.5;
@pad-factor-6: 3;
@pad-factor-7: 3.5;

.pad-mixin-screen-size-thing(~'xs', 0, ~'1', @pad-factor-1);
.pad-mixin-screen-size-thing(~'xs', 0, ~'2', @pad-factor-2);
.pad-mixin-screen-size-thing(~'xs', 0, ~'3', @pad-factor-3);
.pad-mixin-screen-size-thing(~'xs', 0, ~'4', @pad-factor-4);
.pad-mixin-screen-size-thing(~'xs', 0, ~'5', @pad-factor-5);
.pad-mixin-screen-size-thing(~'xs', 0, ~'6', @pad-factor-6);
.pad-mixin-screen-size-thing(~'xs', 0, ~'7', @pad-factor-7);
.pad-mixin-screen-size-thing(~'xs', 0, ~'0', @pad-factor-0);

.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'1', @pad-factor-1);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'2', @pad-factor-2);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'3', @pad-factor-3);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'4', @pad-factor-4);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'5', @pad-factor-5);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'6', @pad-factor-6);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'7', @pad-factor-7);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'0', @pad-factor-0);

.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'1', @pad-factor-1);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'2', @pad-factor-2);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'3', @pad-factor-3);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'4', @pad-factor-4);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'5', @pad-factor-5);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'6', @pad-factor-6);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'7', @pad-factor-7);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'0', @pad-factor-0);

.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'1', @pad-factor-1);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'2', @pad-factor-2);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'3', @pad-factor-3);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'4', @pad-factor-4);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'5', @pad-factor-5);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'6', @pad-factor-6);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'7', @pad-factor-7);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'0', @pad-factor-0);



/// text-alignment mixins ------------------



.text-center{
  text-align: center;
  justify-content: center;
}
.text-right{
  text-align: right;
  justify-content: flex-end;
}
.text-left{
  text-align: left;
  justify-content: flex-start;
}

.type-alignment-screensize-mixin(@screensizename,@screensize-min) {
  @scsize-name: ~"@{screensizename}";
  @media ( min-width: @screensize-min) {
    .text-@{scsize-name}-last-center{
      text-align-last: center;
    }
    .text-@{scsize-name}-last-right{
      text-align-last: right;
    }
    .text-@{scsize-name}-last-left{
      text-align-last: left;
    }

    .text-@{scsize-name}-justify{
      text-align: justify;
    }
    .text-@{scsize-name}-center{
      text-align: center;
      justify-content: center;
    }
    .text-@{scsize-name}-right{
      text-align: right;
      justify-content: flex-end;
    }
    .text-@{scsize-name}-left{
      text-align: left;
      justify-content: flex-start;
    }
  }
}

.type-alignment-screensize-mixin(~'xs', 0);
.type-alignment-screensize-mixin(~'sm', @screen-sm-min);
.type-alignment-screensize-mixin(~'md', @screen-md-min);
.type-alignment-screensize-mixin(~'lg', @screen-lg-min);
