
#scrollLockModal {
  display: block;
  position: fixed;
  width: 100%;
  height: 0;
  z-index: 300;
  top: 0;
  left: 0;
  background: #000d1a;
  opacity: .0;
  cursor: pointer;
  visibility: hidden;
  transition: opacity 0.3s;
  transition-timing-function: ease-in-out;

  &.scrollLock {
    opacity: .95;
    height: 100%;
    mix-blend-mode: multiply;

    visibility: visible;
  }
}

.modal-window {
  @media (max-width: @screen-sm-max) {
    width: 100%;
  }

  // ACTUAL MODAL PROPERTIES -------------------------
  position: fixed;
  border-radius: 2em;
  max-height: 100%;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
  display: block;
  top: -500000%;
  left: 50%;
  transform: translate(-50%) translateY(-50%) scale(0, 0);
  z-index: 301;
  padding-top: 0px;
  padding-bottom: 0px;
  transition: transform 0.3s;
  transition-timing-function: ease-in-out;

  //background: #fff;
  &.open {
    visibility: visible;
    top: 50%;
    transform: translate(-50%) translateY(-50%) scale(1, 1);
    display: block;


  }

  .closeModal {
    font-size: 1em;
    //background: @brand-secondary;
    display: block;
    position: absolute;
    top: 1px;
    right: 1px;
    padding: 0.6em 0.8em;
    z-index: 22;
    text-align: center;
    background: transparent;
    color: #00556e;

    i {
      width: 1em;
      height: 1em;
      color: inherit;
    }
  }
}

.modal-window {
  .row.content-row-cta-modal {
    @media (min-width: @screen-xs-max) {
      display: flex;
      align-items: stretch;
    }

    .column.modal-live-area-column {
    }
  }

  @media (max-width: @screen-xs-max) {
    width: ~'calc(100% - 40px)';
  }

  .row.content-row-cta-modal {
    .column {
      //padding-top: @grid-gutter-width;
      //padding-bottom: @grid-gutter-width;
    }
  }
}

.modal-window {
  h2.modal-title {
  }
  #cta-logo {
    width: 100%;
    max-height: 300px;
    position: relative;
    margin-bottom: (@grid-gutter-width * 0);

    img {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}

#search-modal {
  font-size: 16px;
  background: @brand-gray-light;
  border-radius: .25em;

  max-width: 100%;
  // width :clamp(280px, 500px, 100%);
  height: 100px;

  .content-row-cta-modal {
    height: calc(100% - 2.5em);
    width: 100%;
    margin-top: 1.2em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  form {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;

  }

  input {
    max-width: 100%;
    width: calc(100% - 3em);
    padding: 0.25em 0.5em 0.25em 1em;
    background: transparent;
    color: @text-color;
    border: 2px solid @text-color;
    border-radius: 3em 0 0 3em;
  }

  button {
    width: 3em;
    text-align: center;
    padding: 0 0 0 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 0 3em 3em 0;
    font-size: 1em;
    border: 2px solid @brand-primary;
  }
}

.img-cut {
  position: relative;

  img {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    right: -172px;
  }
}
